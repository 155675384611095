import {
    RESET_CREATE_PLAN_BIOPSY_SHARD,
    SET_ACTIVE_CORE,
    SET_CORE_LOCATIONS,
    SET_PATH_REPORT_BYTE_ARRAY,
    SET_TEMPLATE_BYTE_ARRAY,
    SET_BIOPSY_ERROR,
    SET_IS_CORE_FOCUSED,
    SET_IS_ON_MESH_VIEWER_CONTROL,
} from "./actions";
import { initialState } from "./init";

export default CreatePlanBiopsyReducer;

function CreatePlanBiopsyReducer(state = initialState, action = {}) {
    switch (action.type) {
        case SET_PATH_REPORT_BYTE_ARRAY:
            return setPathReportByteArray(state, action);
        case SET_TEMPLATE_BYTE_ARRAY:
            return setTemplateByteArray(state, action);
        case SET_CORE_LOCATIONS:
            return setCoreLocations(state, action);
        case SET_ACTIVE_CORE:
            return setActiveCore(state, action);
        case RESET_CREATE_PLAN_BIOPSY_SHARD:
            return resetCreatePlanBiopsyShard(state, action);
        case SET_BIOPSY_ERROR:
            return setBiopsyError(state, action);
        case SET_IS_CORE_FOCUSED:
            return setIsCoreFocused(state, action);
        case SET_IS_ON_MESH_VIEWER_CONTROL:
            return setIsOnMeshViewerControl(state, action);
        default:
            return state;
    }
}
function setPathReportByteArray(state, action) {
    return {
        ...state, // Create a shallow copy of the state
        pathReportByteArray: action.bytes, // Update the specific property
    };
}

function setTemplateByteArray(state, action) {
    return {
        ...state, // Create a shallow copy of the state
        templateByteArray: action.bytes, // Update the specific property
    };
}

function setCoreLocations(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.coreLocations = action.cores;
    return stateCpy;
}

function setActiveCore(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.activeCore = action.core;
    return stateCpy;
}

function resetCreatePlanBiopsyShard() {
    return initialState;
}

function setBiopsyError(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.bxError = action.bxError;
    return stateCpy;
}

function setIsCoreFocused(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.isCoreFocused = action.isCoreFocused;
    return stateCpy;
}

function setIsOnMeshViewerControl(state, action) {
    let stateCpy = JSON.parse(JSON.stringify(state));
    stateCpy.isOnMeshViewerControl = action.isOnMeshViewerControl;
    return stateCpy;
}
