import React, { Component } from "react";
import { Form } from "react-bootstrap";
import "./styles.css";
import { isEmpty } from "../../../helpers/helpers";
import { BACKSPACE_KEY, EMPTY_STRING, ENTER_KEY } from "../../../constants";
import {
    DEFAULT_SELECT_OPTION,
    ETHNICITY_CATEGORIES,
    keyPatientInfoCardFieldErrors,
    keyPatientInfoCardFieldUnitsMap,
    keyPatientInfoCardPatientDetailFields,
    keyPatientInfoCardValidationPatterns,
    RACIAL_CATEGORIES,
    MAXIMUM_NAME_LENGTH,
    VISIT_DATE_TEXT,
} from "../constants";
import { handleInvalidDates } from "../helpers";
import PropTypes from "prop-types";
import AHDropdown from "../../__shared__/StyleGuide/AHDropdown";
import AHDropdownItem from "../../__shared__/StyleGuide/AHDropdownItem";

class KeyPatientInfoCard extends Component {
    constructor(props) {
        super(props);

        this.getTableEntries = this.getTableEntries.bind(this);
        this.createInputItem = this.createInputItem.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.updatePatientDataFromEvent =
            this.updatePatientDataFromEvent.bind(this);
        this.updateMetaDataFromEvent = this.updateMetaDataFromEvent.bind(this);
        this.handleKeyDown = this.handleKeyDown.bind(this);
        this.createDropDown = this.createDropDown.bind(this);
        this.handleDropdown = this.handleDropdown.bind(this);
        this.handleSave = this.handleSave.bind(this);
    }

    componentDidMount() {
        this.timerId = null;
    }

    handleKeyDown(e) {
        if (e.keyCode === ENTER_KEY) {
            e.preventDefault();
        }
        if (e.key.length === 1 || e.keyCode === BACKSPACE_KEY) {
            this.handleSave();
        }
    }

    handleSave() {
        clearInterval(this.timerId);
        this.timerId = setTimeout(() => {
            this.props.autoSaveCallback();
        }, 2000);
    }

    isPSAValid(pd) {
        return keyPatientInfoCardValidationPatterns
            .get(keyPatientInfoCardPatientDetailFields.PSA)
            .test(pd.PSA);
    }

    isPatientDataValid(pd) {
        return (
            keyPatientInfoCardValidationPatterns
                .get(keyPatientInfoCardPatientDetailFields.F_NAME)
                .test(pd.FirstName) &&
            this.checkNameLength(pd.FirstName, MAXIMUM_NAME_LENGTH) &&
            keyPatientInfoCardValidationPatterns
                .get(keyPatientInfoCardPatientDetailFields.M_NAME)
                .test(pd.MiddleName) &&
            this.checkNameLength(pd.MiddleName, MAXIMUM_NAME_LENGTH) &&
            keyPatientInfoCardValidationPatterns
                .get(keyPatientInfoCardPatientDetailFields.L_NAME)
                .test(pd.LastName) &&
            this.checkNameLength(pd.LastName, MAXIMUM_NAME_LENGTH) &&
            keyPatientInfoCardValidationPatterns
                .get(keyPatientInfoCardPatientDetailFields.P_MRN)
                .test(pd.PatientMRN) &&
            this.checkNameLength(pd.PatientMRN, MAXIMUM_NAME_LENGTH) &&
            keyPatientInfoCardValidationPatterns
                .get(keyPatientInfoCardPatientDetailFields.DOB)
                .test(pd.DOB) &&
            keyPatientInfoCardValidationPatterns
                .get(keyPatientInfoCardPatientDetailFields.PSA)
                .test(pd.PSA)
        );
    }

    checkNameLength(name, maximumLength) {
        return !name ? true : name.length <= maximumLength;
    }

    updatePatientDataFromEvent(patientData, event) {
        let pd = patientData;
        switch (event.target.name) {
            case keyPatientInfoCardPatientDetailFields.F_NAME:
                pd.FirstName = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.M_NAME:
                pd.MiddleName = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.L_NAME:
                pd.LastName = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.P_MRN:
                pd.PatientMRN = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.DOB:
                pd.DOB = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.RACE:
                pd.Race = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.ETH:
                pd.Ethnicity = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.PSA:
                pd.PSA = event.target.value;
                break;
            default:
                break;
        }

        return pd;
    }

    updateMetaDataFromEvent(metaData, event) {
        let md = metaData;
        switch (event.target.name) {
            case keyPatientInfoCardPatientDetailFields.F_NAME:
                md.FirstName = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.M_NAME:
                md.MiddleName = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.L_NAME:
                md.LastName = event.target.value;
                break;
            case keyPatientInfoCardPatientDetailFields.P_MRN:
                md.PatientMRN = event.target.value;
                break;
            default:
                break;
        }

        return md;
    }

    handleChange(event) {
        let pd = this.updatePatientDataFromEvent(this.props.patientData, event);
        let md = this.updateMetaDataFromEvent(this.props.metaData, event);

        this.props.updatePatientDetails(pd, md);
    }

    getErrorMessage(name, value, isFreehandPlan) {
        if (
            name === keyPatientInfoCardPatientDetailFields.PSA &&
            isFreehandPlan
        ) {
            return null;
        }
        let re = keyPatientInfoCardValidationPatterns.get(name);
        let errVal;
        if (name === keyPatientInfoCardPatientDetailFields.ETH) {
            errVal = ETHNICITY_CATEGORIES.includes(value)
                ? EMPTY_STRING
                : keyPatientInfoCardFieldErrors.get(name);
        } else if (name === keyPatientInfoCardPatientDetailFields.RACE) {
            errVal = RACIAL_CATEGORIES.includes(value)
                ? EMPTY_STRING
                : keyPatientInfoCardFieldErrors.get(name);
        } else if (name === keyPatientInfoCardPatientDetailFields.DOB) {
            errVal = handleInvalidDates(value);
        } else if (
            name === keyPatientInfoCardPatientDetailFields.F_NAME ||
            name === keyPatientInfoCardPatientDetailFields.M_NAME ||
            name === keyPatientInfoCardPatientDetailFields.L_NAME ||
            name === keyPatientInfoCardPatientDetailFields.P_MRN
        ) {
            errVal =
                re.test(value) &&
                this.checkNameLength(value, MAXIMUM_NAME_LENGTH)
                    ? EMPTY_STRING
                    : keyPatientInfoCardFieldErrors.get(name);
        } else {
            errVal = re.test(value)
                ? EMPTY_STRING
                : keyPatientInfoCardFieldErrors.get(name);
        }

        return errVal !== EMPTY_STRING ? (
            <small className={"text-danger text-center d-inline-block ml-2"}>
                {errVal}
            </small>
        ) : null;
    }

    createInputItem(name, value) {
        let errDiv = this.getErrorMessage(
            name,
            value,
            this.props.isFreehandPlan
        );

        const defaultInputValue = value || EMPTY_STRING;

        return (
            <tbody>
                <tr>
                    <td className={"pinfo-left-item"}>{name}</td>
                    <td className={"pinfo-right-item pendo-ignore"}>
                        <div className={"pinfo-text-field"}>
                            <Form>
                                <Form.Control
                                    onKeyDown={this.handleKeyDown}
                                    className={
                                        "d-inline-block text-center form-control form-control-sm w-auto "
                                    }
                                    name={name}
                                    value={defaultInputValue}
                                    onChange={this.handleChange}
                                    type={"text"}
                                    noValidate
                                />
                                <span className="glyphicon form-control-feedback ml-1">
                                    {keyPatientInfoCardFieldUnitsMap.get(name)}
                                </span>
                            </Form>
                            {errDiv}
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    handleDropdown(e, name) {
        let event = {
            target: {
                name: name,
                value: e,
            },
        };
        this.handleSave();
        this.handleChange(event);
    }

    createDropDown(name, value) {
        let category =
            name === keyPatientInfoCardPatientDetailFields.ETH
                ? ETHNICITY_CATEGORIES
                : RACIAL_CATEGORIES;
        if (!value) {
            value = DEFAULT_SELECT_OPTION;
        }

        return (
            <tbody>
                <tr>
                    <td className={"pinfo-left-item"}>{name}</td>
                    <td className={"pinfo-right-item pendo-ignore"}>
                        <div className={"pinfo-dropdown-cntr"}>
                            <AHDropdown
                                title={value}
                                id={`${name}-select`}
                                style={{ maxWidth: "172px", height: "31px" }}
                                preventDisplayTextOverflow={true}
                                onSelect={(e) => {
                                    this.handleDropdown(e, name);
                                }}
                            >
                                {category.map((item) => (
                                    <AHDropdownItem eventKey={item} key={item}>
                                        {item}
                                    </AHDropdownItem>
                                ))}
                            </AHDropdown>
                        </div>
                    </td>
                </tr>
            </tbody>
        );
    }

    getTableEntries() {
        let tableEntries = new Map();

        if (isEmpty(this.props.patientData)) {
            return tableEntries;
        }

        let pd = this.props.patientData;

        tableEntries.set(
            keyPatientInfoCardPatientDetailFields.F_NAME,
            this.createInputItem(
                keyPatientInfoCardPatientDetailFields.F_NAME,
                pd.FirstName
            )
        );

        tableEntries.set(
            keyPatientInfoCardPatientDetailFields.M_NAME,
            this.createInputItem(
                keyPatientInfoCardPatientDetailFields.M_NAME,
                pd.MiddleName
            )
        );

        tableEntries.set(
            keyPatientInfoCardPatientDetailFields.L_NAME,
            this.createInputItem(
                keyPatientInfoCardPatientDetailFields.L_NAME,
                pd.LastName
            )
        );

        tableEntries.set(
            keyPatientInfoCardPatientDetailFields.P_MRN,
            this.createInputItem(
                keyPatientInfoCardPatientDetailFields.P_MRN,
                pd.PatientMRN
            )
        );

        tableEntries.set(
            keyPatientInfoCardPatientDetailFields.DOB,
            this.createInputItem(
                keyPatientInfoCardPatientDetailFields.DOB,
                pd.DOB
            )
        );

        tableEntries.set(
            keyPatientInfoCardPatientDetailFields.RACE,
            this.createDropDown(
                keyPatientInfoCardPatientDetailFields.RACE,
                pd.Race
            )
        );

        tableEntries.set(
            keyPatientInfoCardPatientDetailFields.ETH,
            this.createDropDown(
                keyPatientInfoCardPatientDetailFields.ETH,
                pd.Ethnicity
            )
        );

        tableEntries.set(
            keyPatientInfoCardPatientDetailFields.PSA,
            this.createInputItem(
                keyPatientInfoCardPatientDetailFields.PSA,
                pd.PSA
            )
        );

        return tableEntries;
    }

    render() {
        let upldState = this.props.isBiopsyDataParsed ? "finished" : "waiting";

        let tableItems = this.getTableEntries();

        return (
            <div
                id={this.props.id}
                className={`key-pinfo-container ${upldState}`}
                style={this.props.style}
            >
                <div className={"key-pinfo-container-header"}>
                    <p className="display-30 first-header">
                        Key Patient Information
                    </p>
                    <p className="display-16 right-header">
                        {VISIT_DATE_TEXT}: {this.props.visitDate}
                    </p>
                </div>
                <div
                    id={this.props.id}
                    className={"key-pinfo-card"}
                    style={this.props.style}
                >
                    <table className={"pinfo-left-col"}>
                        {/* First Name */}
                        {tableItems.get(
                            keyPatientInfoCardPatientDetailFields.F_NAME
                        )}
                        {/* Middle Name */}
                        {tableItems.get(
                            keyPatientInfoCardPatientDetailFields.M_NAME
                        )}
                        {/* Last Name */}
                        {tableItems.get(
                            keyPatientInfoCardPatientDetailFields.L_NAME
                        )}
                        {/* Medical Record Number */}
                        {tableItems.get(
                            keyPatientInfoCardPatientDetailFields.P_MRN
                        )}
                    </table>
                    <table className={"pinfo-right-col"}>
                        {/* Date of Birth */}
                        {tableItems.get(
                            keyPatientInfoCardPatientDetailFields.DOB
                        )}
                        {/* Ethnicity */}
                        {tableItems.get(
                            keyPatientInfoCardPatientDetailFields.ETH
                        )}
                        {/* Race */}
                        {tableItems.get(
                            keyPatientInfoCardPatientDetailFields.RACE
                        )}
                        {/* PSA */}
                        {tableItems.get(
                            keyPatientInfoCardPatientDetailFields.PSA
                        )}
                    </table>
                </div>
            </div>
        );
    }
}

KeyPatientInfoCard.propTypes = {
    isBiopsyDataParsed: PropTypes.bool,
    isFreehandPlan: PropTypes.bool,
    id: PropTypes.string,
    style: PropTypes.object,
    patientData: PropTypes.object,
    metaData: PropTypes.object,
    updatePatientDetails: PropTypes.func,
    autoSaveCallback: PropTypes.func,
    visitDate: PropTypes.string,
};

KeyPatientInfoCard.defaultProps = {
    isBiopsyDataParsed: false,
    id: EMPTY_STRING,
    style: {},
    patientData: {},
    metaData: {},
    updatePatientDetails: undefined,
    autoSaveCallback: undefined,
    visitDate: EMPTY_STRING,
};

export default KeyPatientInfoCard;
