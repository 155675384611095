import { vsprintf } from "sprintf-js";
import {
    API_ENDPOINT,
    API_VERSION,
    TASKS_API_ENDPOINT,
    SUCCESS,
    STATUS_OK,
    httpMethods,
    clientErrorMessages,
    EMPTY_STRING,
    FAILURE,
    USER_NOT_FOUND,
} from "../constants";
import { rootStore } from "../redux/store";
import { setErrorState } from "../redux/error_banner/actions";
import { NAV_PLAN_OPTIONS } from "../components/__shared__/DownloadSelectCard/constants";

export async function checkToken(token) {
    return await fetch(
        vsprintf("%s/api/%s/authCheck", [API_ENDPOINT, API_VERSION]),
        {
            method: httpMethods.GET,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            mode: "cors",
        }
    )
        .then((response) => response.status < 300)
        .catch(
            () =>
                // suppressed for now since unauthorized requests have no CORS
                // rootStore.dispatch(setErrorState(true, String(e)));
                null
        );
}

export async function getPHISignedURLS3(input) {
    if (!input.uris) {
        input.uris = [];
    }
    input.uris = input.uris.map((uri) => (!uri ? EMPTY_STRING : uri));

    return await fetch(
        vsprintf("%s/api/%s/data/phi/patient/%s/visit/%s/file", [
            API_ENDPOINT,
            API_VERSION,
            input.patientuuid,
            input.visituuid,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                uris: input.uris,
            }),
            mode: "cors",
        }
    );
}

export async function getFileS3XML(signedUrl) {
    let req = new XMLHttpRequest();

    return await new Promise(function (resolve, reject) {
        req.onreadystatechange = function () {
            // Only run if the request is complete
            if (req.readyState !== 4) return;

            // Process the response
            if (req.status >= STATUS_OK && req.status < 300) {
                // If successful
                resolve(req);
            } else {
                // If failed
                reject({
                    status: req.status,
                    statusText: req.statusText,
                });
            }
        };

        req.onprogress = function (e) {
            if (e.lengthComputable) {
                // console.log((e.loaded / e.total) * 100);
                //callback((e.loaded / e.total) * 100)
            }
        };

        req.open(httpMethods.GET, signedUrl, true);
        req.setRequestHeader("Content-type", "binary/octet-stream");
        req.responseType = "arraybuffer";
        req.send();
    });
}

export async function downloadTreatmentPlan(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/patient/%s/plan/%s/sync", [
        TASKS_API_ENDPOINT,
        API_VERSION,
        input.useruuid,
        input.patientuuid,
        input.planuuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.PLAN_DOWNLOAD_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function createProfuseExport(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/patient/%s/plan/%s/navPack", [
        TASKS_API_ENDPOINT,
        API_VERSION,
        input.useruuid,
        input.patientuuid,
        input.planuuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        body: JSON.stringify({
            treatmentPlan: input.treatmentPlan,
            exportPlatform: input.exportPlatform,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
        });
}

export async function createStandardDicomExport(input) {
    let urlSuffix;
    if (input.exportFormat === NAV_PLAN_OPTIONS.DICOM_RT) {
        urlSuffix = "rtstruct";
    } else if (input.exportFormat === NAV_PLAN_OPTIONS.DICOM_SSEG) {
        urlSuffix = "sseg";
    }
    let endpoint = vsprintf(
        "%s/api/%s/user/%s/patient/%s/plan/%s/export/dicom/%s",
        [
            TASKS_API_ENDPOINT,
            API_VERSION,
            input.useruuid,
            input.patientuuid,
            input.planuuid,
            urlSuffix,
        ]
    );

    return await fetch(endpoint, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        body: JSON.stringify({
            treatmentPlan: input.treatmentPlan,
            includeToolSites: input.includeToolSites,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
        });
}

export async function createPDFExport(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/patient/%s/plan/%s/export/pdf", [
        TASKS_API_ENDPOINT,
        API_VERSION,
        input.useruuid,
        input.patientuuid,
        input.planuuid,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.POST,
        body: JSON.stringify({
            metaTreatmentPlan: input.metaTreatmentPlan,
            userData: input.userData,
            includeToolSites: input.includeToolSites,
            cemWarningMsg: input.cemWarningMsg,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
        });
}

export async function getBoundingCone(sphere, bx) {
    return await fetch(
        vsprintf("%s/api/%s/proc/misc/phi/tx/boundingCone", [
            API_ENDPOINT,
            API_VERSION,
        ]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                boundingSphere: sphere,
                bxCores: bx,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function updateTreatmentPlan(input) {
    return await fetch(
        vsprintf("%s/api/%s/patient/%s/plan/%s", [
            API_ENDPOINT,
            API_VERSION,
            input.patientuuid,
            input.planuuid,
        ]),
        {
            method: httpMethods.PUT,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                plan: input.plan,
                reset: input.reset,
                userUuid: input.useruuid,
            }),
            mode: "cors",
        }
    ).catch((e) => {
        rootStore.dispatch(setErrorState(true, String(e)));
        return null;
    });
}

export async function getMeshProperties(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/misc/phi/tx/meshProperties", [
            API_ENDPOINT,
            API_VERSION,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                uri: input.uri,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function createAISegmentation(input) {
    let endpoint = vsprintf("%s/api/%s/proc/seg/mri/prostate", [
        API_ENDPOINT,
        API_VERSION,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.POST,
        body: JSON.stringify({
            outputURI: input.outputURI,
            mriURIs: input.mriURIs,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            throw new Error(clientErrorMessages.SEGMENTATION_CREATE_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function sendPasswordReset(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/sendPwReset", [API_ENDPOINT, API_VERSION]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                email: input.email,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function setNewPassword(input) {
    return await fetch(
        vsprintf("%s/api/%s/user/%s/resetPw", [
            API_ENDPOINT,
            API_VERSION,
            input.username,
        ]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                username: input.username,
                password: input.password,
                resetToken: input.resetToken,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function createCPM(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/mpred/cpm", [
            API_ENDPOINT,
            API_VERSION,
            input.username,
        ]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                prostateURI: input.prostateURI,
                roiURIs: input.roiURIs,
                mriURIs: input.mriURIs,
                bxCores: input.bxCores,
                outputURI: input.outputURI,
                psa: input.psa,
                stride: input.stride,
                useruuid: input.useruuid,
                patientuuid: input.patientuuid,
                planuuid: input.planuuid,
                authToken: input.authToken,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch(() => null);
}

export async function createMargin(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/mpred/margin", [
            API_ENDPOINT,
            API_VERSION,
            input.username,
        ]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                prostateURI: input.prostateURI,
                cpmURI: input.cpmURI,
                customCPMThreshold: input.customCPMThreshold,
                cropToMidline: input.cropToMidline,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function cancerMapGenerationComplete_API(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/mpred/cancer_map_generation_complete", [
            API_ENDPOINT,
            API_VERSION,
            input.username,
        ]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                requestuuid: input.requestuuid,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch(() => null);
}

export function createOptimalMargin(input) {
    return fetch(
        vsprintf("%s/api/%s/proc/mpred/margin", [API_ENDPOINT, API_VERSION]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                prostateURI: input.prostateURI,
                cpmURI: input.cpmURI,
                searchForOptimalCPM: true,
            }),
            mode: "cors",
            retries: 60,
            retryDelay: 10000,
            retryOn: [400, 500],
        }
    );
}

export function estimateMeshVolume(input) {
    return fetch(
        vsprintf("%s/api/%s/proc/mesh/volume", [
            TASKS_API_ENDPOINT,
            API_VERSION,
        ]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                meshURI: input.uri,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }

            throw new Error(clientErrorMessages.ESTIMATE_MESH_VOLUME_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export function createMeshFromFaceVertexInS3(input) {
    return fetch(
        vsprintf("%s/api/%s/proc/mesh", [TASKS_API_ENDPOINT, API_VERSION]),
        {
            method: httpMethods.POST,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                faces: Array.from(input.faces),
                vertices: Array.from(input.vertices),
                destinationURI: input.destinationURI,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }

            throw new Error(clientErrorMessages.SAVE_MESH_UPDATE_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export function deleteAblationTargetBackend(input) {
    return fetch(
        vsprintf("%s/api/%s/patient/%s/plan/%s/txTarget", [
            API_ENDPOINT,
            API_VERSION,
            input.patientuuid,
            input.planuuid,
        ]),
        {
            method: httpMethods.DELETE,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            body: JSON.stringify({
                userUuid: input.useruuid,
                visitUuid: input.visituuid,
                target: {
                    ID: input.id,
                },
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function getAutoPlacedTargets(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/atp", [TASKS_API_ENDPOINT, API_VERSION]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                prostateURI: input.prostateURI,
                marginURI: input.marginURI,
                roiURIs: input.roiURIs,
                bxCores: input.bxCores,
                ablationVolume: input.ablationVolume,
                cloudIDs: input.cloudIDs,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function getAblationVolumeIsValidForAutoPlacement(input) {
    return await fetch(
        vsprintf("%s/api/%s/proc/atp/isValid", [
            TASKS_API_ENDPOINT,
            API_VERSION,
        ]),
        {
            method: httpMethods.POST,
            body: JSON.stringify({
                prostateURI: input.prostateURI,
                marginURI: input.marginURI,
                roiURIs: input.roiURIs,
                bxCores: input.bxCores,
                ablationVolume: input.ablationVolume,
                cloudIDs: input.cloudIDs,
            }),
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function deleteFileFromCloud(input) {
    let key = input.fileURI.replace(/\//g, "|");
    return await fetch(
        vsprintf("%s/api/%s/data/phi/patient/%s/visit/%s/file/%s", [
            API_ENDPOINT,
            API_VERSION,
            input.patientuuid,
            input.visituuid,
            key,
        ]),
        {
            method: httpMethods.DELETE,
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${input.token}`,
            },
            mode: "cors",
        }
    )
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }

            throw new Error(clientErrorMessages.FILE_DELETE_FAILED);
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export function reportBug(input) {
    let endpoint = vsprintf("%s/api/%s/user/%s/bug", [
        API_ENDPOINT,
        API_VERSION,
        input.user_id,
    ]);
    return fetch(endpoint, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        body: JSON.stringify({
            systemUUID: input.user_id,
            email: input.email,
            subject: input.subject,
            body: input.body,
        }),
        mode: "cors",
    })
        .then((response) => response.json())
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function getInstitutionUsers(input) {
    const endpoint = vsprintf("%s/api/%s/institution/%s/users", [
        API_ENDPOINT,
        API_VERSION,
        input.userInstitution,
    ]);

    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.authToken}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS && json.payload.status === SUCCESS) {
                return json.payload;
            }
            if (json.message === FAILURE || json.message === USER_NOT_FOUND) {
                return { userList: [] };
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

// PATIENT SHARING APIS
export async function checkSharedPatient(input) {
    let endpoint = vsprintf("%s/api/%s/patient/%s/shared", [
        API_ENDPOINT,
        API_VERSION,
        input.patientuuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (
                json.message === SUCCESS &&
                json.payload.patientShared === true
            ) {
                return json.payload;
            }
        })
        .catch(() => null);
}

export async function acquirePlanLock(input) {
    let endpoint = vsprintf("%s/api/%s/plan/%s/lock/user/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.planuuid,
        input.useruuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.POST,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function releasePlanLock(input) {
    let endpoint = vsprintf("%s/api/%s/plan/%s/lock/user/%s", [
        API_ENDPOINT,
        API_VERSION,
        input.planuuid,
        input.useruuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.DELETE,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
        })
        .catch((e) => {
            rootStore.dispatch(setErrorState(true, String(e)));
            return null;
        });
}

export async function checkPlanLocked(input) {
    let endpoint = vsprintf("%s/api/%s/plan/%s/lock", [
        API_ENDPOINT,
        API_VERSION,
        input.planuuid,
    ]);
    return await fetch(endpoint, {
        method: httpMethods.GET,
        headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${input.token}`,
        },
        mode: "cors",
    })
        .then((response) => response.json())
        .then((json) => {
            if (json.message === SUCCESS) {
                return json.payload;
            }
        })
        .catch(() => null);
}
