import PropTypes from "prop-types";

const Rotate = ({ onClick, stroke = "white" }) => {
    return (
        <button className="unstyled-btn" onClick={onClick}>
            <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
            >
                <g clipPath="url(#clip0_347_1005)">
                    <path
                        d="M15.3333 2.66663V6.66663H11.3333"
                        stroke={stroke}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M13.66 10.0001C13.2266 11.2267 12.4064 12.2792 11.3228 12.9991C10.2392 13.7189 8.95097 14.0671 7.65228 13.9911C6.35359 13.9151 5.11477 13.4191 4.1225 12.5778C3.13024 11.7365 2.43828 10.5955 2.15091 9.32675C1.86354 8.05798 1.99632 6.73017 2.52924 5.54343C3.06217 4.35668 3.96636 3.37529 5.10557 2.74714C6.24478 2.11899 7.55729 1.87812 8.84531 2.06081C10.1333 2.24351 11.3271 2.83988 12.2467 3.76005L15.3333 6.66672"
                        stroke={stroke}
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_347_1005">
                        <rect width="16" height="16" fill="white" />
                    </clipPath>
                </defs>
            </svg>
        </button>
    );
};

Rotate.propTypes = {
    onClick: PropTypes.func,
    stroke: PropTypes.string,
};

export default Rotate;
