import React, { Component } from "react";
import "./styles.css";
import AHDropdown from "../../StyleGuide/AHDropdown";
import AHButton from "../../StyleGuide/AHButton";
import { createUserPatientShare } from "../../../CreateTPlan_1Upload/helpers";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import AHDropdownItem from "../../StyleGuide/AHDropdownItem";
import {
    acquirePlanLock,
    getInstitutionUsers,
} from "../../../../helpers/backend_api";
import {
    clientErrorMessages,
    EMPTY_STRING,
    N_A_STRING,
    PATIENT_ALREADY_SHARED,
    SUCCESS,
} from "../../../../constants";
import CheckCircle from "../../IconComponents/CheckCircle";
import { Else, If, Then, When } from "react-if";
import Alert from "../../Alert";
import {
    PATIENT_SHARE_BUTTON_TEXT,
    PATIENT_SHARE_DR_PREFIX,
    PATIENT_SHARE_NO_DR_AVAIL,
    PATIENT_SHARE_NO_PATIENT_DATA,
    PATIENT_SHARE_PINFO_HEADER_DOB,
    PATIENT_SHARE_PINFO_HEADER_ID,
    PATIENT_SHARE_PINFO_HEADER_PSA,
    PATIENT_SHARE_QUESTION,
    SELECT_DOCTOR,
    TIME_OUT_DURATION_MS,
} from "../constants";
import { filterAvendaHelperAccounts } from "../helpers";
import { VISIT_DATE_TEXT } from "../../../CreateTPlan_1Upload/constants";

class PatientShareTab extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedDoctor: SELECT_DOCTOR.DEFAULT,
            doctorList: [],
            shareSuccess: false,
            shareFailureAlert: false,
            alertMessage: EMPTY_STRING,
            selectedDoctorUuid: undefined,
        };

        this.getDoctorList = this.getDoctorList.bind(this);
        this.selectDoctor = this.selectDoctor.bind(this);
    }

    componentDidMount() {
        this.getDoctorList();
    }

    showShareFailureAlert() {
        this.setState({ shareFailureAlert: true }, () => {
            this.alertTimeout = setTimeout(() => {
                this.setState({ shareFailureAlert: false });
            }, TIME_OUT_DURATION_MS);
        });
    }

    getDoctorList() {
        getInstitutionUsers({
            userInstitution: this.props.userData.institution,
            authToken: this.props.authToken,
        }).then((response) => {
            if (response && response.userList && response.userList.length > 0) {
                const unfilteredDoctorList = response.userList
                    .filter(
                        (doctor) => doctor.uuid !== this.props.userData.uuid
                    )
                    .map((doctor) => ({
                        firstName: doctor.firstName,
                        lastName: doctor.lastName,
                        uuid: doctor.uuid,
                        email: doctor.email,
                    }));
                const doctorList = filterAvendaHelperAccounts(
                    unfilteredDoctorList,
                    this.props.userData
                );
                this.setState({ doctorList });
            }
        });
    }

    async sharePatient() {
        createUserPatientShare(
            this.props.authToken,
            this.state.selectedDoctorUuid,
            this.props.currentTreatmentPlan.TreatmentPlan.PatientData
                .PatientUUID
        ).then(async (response) => {
            if (response.status === SUCCESS) {
                await acquirePlanLock({
                    token: this.props.authToken,
                    planuuid: this.props.planUuid,
                    useruuid: this.props.userData.uuid,
                });
                this.setState({
                    shareSuccess: true,
                    shareFailureAlert: false,
                });
                if (window.pendo) {
                    try {
                        window.pendo.track("Share Patient", {
                            planId: this.props.planUuid,
                            sharedDoctorUUID: this.state.selectedDoctorUuid,
                        });
                    } catch {
                        () => {};
                    }
                }
            } else if (response.status === PATIENT_ALREADY_SHARED) {
                this.setState({
                    shareSuccess: false,
                    shareFailureAlert: true,
                    alertMessage: clientErrorMessages.PATIENT_ALREADY_SHARED,
                });
                this.showShareFailureAlert();
            } else {
                this.setState({
                    shareSuccess: false,
                    shareFailureAlert: true,
                    alertMessage: clientErrorMessages.FAILED_TO_SHARE_PATIENT,
                });
                this.showShareFailureAlert();
            }
        });
    }

    selectDoctor(key) {
        clearTimeout(this.alertTimeout);

        const index = parseInt(key, 10);
        if (index >= 0 && index < this.state.doctorList.length) {
            const selectedDoctorData = this.state.doctorList[index];
            this.setState({
                selectedDoctor: `${PATIENT_SHARE_DR_PREFIX} ${selectedDoctorData.firstName} ${selectedDoctorData.lastName}`,
                selectedDoctorUuid: selectedDoctorData.uuid,
                shareSuccess: false,
                shareFailureAlert: false,
            });
        }
    }

    render() {
        const { currentTreatmentPlan } = this.props;

        if (
            !currentTreatmentPlan ||
            !currentTreatmentPlan.TreatmentPlan ||
            !currentTreatmentPlan.TreatmentPlan.PatientData
        ) {
            return (
                <div className="patient-share-empty-text display-regular-16 text-gray">
                    {PATIENT_SHARE_NO_PATIENT_DATA}
                </div>
            );
        }

        const { PatientData: patientData, HeaderData: headerData } =
            currentTreatmentPlan.TreatmentPlan;

        return (
            <div className="patient-share-tab">
                <When condition={this.state.shareFailureAlert}>
                    <Alert
                        variant="danger"
                        text={this.state.alertMessage}
                        className="patient-share-alert"
                    />
                </When>
                <div className="display-regular-18 pendo-ignore">
                    {`${patientData.FirstName} ${patientData.LastName}`}
                </div>
                <div className="patient-share-pinfo pendo-ignore">
                    <div>
                        <div className="display-regular-12 text-gray">
                            {PATIENT_SHARE_PINFO_HEADER_ID}
                        </div>
                        <div className="display-regular-16">
                            {patientData.PatientMRN}
                        </div>
                    </div>
                    <div>
                        <div className="display-regular-12 text-gray">
                            {PATIENT_SHARE_PINFO_HEADER_DOB}
                        </div>
                        <div className="display-regular-16">
                            {patientData.DOB}
                        </div>
                    </div>
                    <div>
                        <div className="display-regular-12 text-blue">
                            {PATIENT_SHARE_PINFO_HEADER_PSA}
                        </div>
                        <div className="display-regular-16">
                            {patientData.PSA || N_A_STRING}
                        </div>
                    </div>
                </div>
                <div className="patient-share-dropdown display-regular-14 text-gray">
                    <div>{PATIENT_SHARE_QUESTION}</div>
                    <AHDropdown
                        className="patient-share-dropdown-toggle"
                        title={this.state.selectedDoctor}
                        onSelect={(eventKey) => this.selectDoctor(eventKey)}
                    >
                        {this.state.doctorList.length > 0 ? (
                            this.state.doctorList.map((doctor, index) => (
                                <AHDropdownItem
                                    key={index}
                                    id={`pt-share-dropdown-${index}-btn`}
                                    eventKey={`${index}`}
                                >
                                    {PATIENT_SHARE_DR_PREFIX} {doctor.firstName}{" "}
                                    {doctor.lastName}
                                    <br />
                                    {doctor.email}
                                </AHDropdownItem>
                            ))
                        ) : (
                            <AHDropdownItem
                                id="pt-share-dropdown-empty-btn"
                                disabled
                            >
                                {PATIENT_SHARE_NO_DR_AVAIL}
                            </AHDropdownItem>
                        )}
                    </AHDropdown>
                </div>
                <If
                    condition={
                        this.state.selectedDoctor === SELECT_DOCTOR.DEFAULT
                    }
                >
                    <Then>
                        <AHButton
                            id="pt-share-share-info-btn-unselected"
                            className="patient-share-button-unselected"
                            text={PATIENT_SHARE_BUTTON_TEXT}
                        />
                    </Then>
                    <Else>
                        <div className="patient-share-button-selected">
                            <AHButton
                                id="pt-share-share-info-btn-selected"
                                className="patient-share-button"
                                onClick={() => this.sharePatient()}
                                text={PATIENT_SHARE_BUTTON_TEXT}
                            />
                            <When condition={this.state.shareSuccess}>
                                <CheckCircle
                                    fill={getComputedStyle(
                                        document.body
                                    ).getPropertyValue(
                                        "--avendaStyleGuideToolSiteGreen"
                                    )}
                                    className="patient-share-check"
                                />
                            </When>
                        </div>
                    </Else>
                </If>
                <div className="patient_information">
                    <div className="display-regular-12 text-gray">
                        Patient ID:
                    </div>
                    <div className="display-regular-16 user-select-text patient-uuid pendo-ignore">
                        {patientData.PatientUUID}
                    </div>
                    <div className="display-regular-12 text-gray">Plan ID:</div>
                    <div className="display-regular-16 user-select-text plan-uuid pendo-ignore">
                        {headerData.PlanUUID}
                    </div>
                    <div className="display-regular-12 text-gray">
                        {VISIT_DATE_TEXT}:
                    </div>
                    <div className="display-regular-16 user-select-text plan-uuid pendo-ignore">
                        {this.props.visitDate}
                    </div>
                </div>
            </div>
        );
    }
}

PatientShareTab.propTypes = {
    userData: PropTypes.object,
    authToken: PropTypes.string,
    setUserData: PropTypes.func,
    currentTreatmentPlan: PropTypes.object,
    planUuid: PropTypes.string,
    visitDate: PropTypes.string,
};

PatientShareTab.defaultProps = {
    authToken: EMPTY_STRING,
    currentTreatmentPlan: {},
    visitDate: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        userData: state.LoginReducer.userData,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        planUuid: state.CreatePlanReducer.planuuid,
        visitDate: state.CreatePlanReducer.visitDate,
    };
};

export default connect(mapStateToProps)(PatientShareTab);
