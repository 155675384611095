import React, { Component } from "react";
import { connect } from "react-redux";
import {
    setCurrentTreatmentPlan,
    setStepActive,
    setStepComplete,
} from "../../CreateTPlan_1Upload/actions";
import "./styles.css";
import { isEmpty } from "../../../helpers/helpers";
import { EMPTY_STRING } from "../../../constants";
import DecisionAlertCard from "../../__shared__/DecisionAlertCard";
import PropTypes from "prop-types";
import uncheckedSquare from "../../../assets/UncheckedSquare.svg";
import checkedSquare from "../../../assets/CheckedSquare.svg";
import { prostateSegOptions } from "../constants";
import PatientAnatomyModel from "../../__shared__/Viewers/Structures/PatientAnatomy/PatientAnatomyModel";
import { If, Then, Else } from "react-if";

class Step2ConfirmPrompt extends Component {
    constructor(props) {
        super(props);

        this.state = {
            selectedProstateSegmentation: prostateSegOptions.AI_SEG,
        };

        this.onYesClick = this.onYesClick.bind(this);
        this.onNoClick = this.onNoClick.bind(this);
        this.onBoxClick = this.onBoxClick.bind(this);
    }

    componentDidMount() {
        let prostateAI = this.props.patientAnatomyModel.prostateAI;
        if (prostateAI && prostateAI.visibility) {
            this.setState({
                selectedProstateSegmentation: prostateSegOptions.AI_SEG,
            });
        } else {
            this.setState({
                selectedProstateSegmentation: prostateSegOptions.UPLOADED_SEG,
            });
        }
    }

    async onYesClick() {
        if (this.props.onYesClick) {
            await this.props.onYesClick(
                this.state.selectedProstateSegmentation
            );
        }
    }

    onNoClick() {
        if (this.props.onNoClick) {
            this.props.onNoClick();
        }
    }

    onBoxClick(option) {
        this.setState({ selectedProstateSegmentation: option });
    }

    renderSingleSegmentationDecisionAlertCard() {
        return (
            <DecisionAlertCard
                id={"review-seg-alert"}
                noBtnCallback={this.onNoClick}
                noBtnTxt={"Review"}
                yesBtnTxt={"Confirm"}
                yesBtnCallback={this.onYesClick}
            >
                <div className={"review-seg-alert-card-header"}>
                    <p className={"display-26"}>
                        Confirm Prostate Segmentation?
                    </p>
                </div>
                <div className={"review-seg-alert-card-body"}>
                    <p className={"display-16"}>
                        Please ensure you have reviewed the prostate
                        segmentation for accuracy.
                    </p>
                </div>
            </DecisionAlertCard>
        );
    }

    renderMultipleSegmentationDecisionAlertCard() {
        let prostateAIColor = this.props.patientAnatomyModel.prostateAI
            ? `#${this.props.patientAnatomyModel.prostateAI.color.getHexString()}`
            : "#FFFFFF";
        let prostateUploadColor = this.props.patientAnatomyModel
            .prostateUploaded
            ? `#${this.props.patientAnatomyModel.prostateUploaded.color.getHexString()}`
            : "#FFFFFF";

        let prostateUploadBox =
            this.state.selectedProstateSegmentation ===
            prostateSegOptions.UPLOADED_SEG
                ? checkedSquare
                : uncheckedSquare;
        let prostateAIBox =
            this.state.selectedProstateSegmentation ===
            prostateSegOptions.AI_SEG
                ? checkedSquare
                : uncheckedSquare;

        return (
            <DecisionAlertCard
                id={"review-seg-alert"}
                noBtnCallback={this.onNoClick}
                noBtnTxt={"Review"}
                yesBtnTxt={"Confirm"}
                yesBtnCallback={this.onYesClick}
            >
                <div className={"review-seg-alert-card-header"}>
                    <p className={"display-26"}>
                        Confirm Prostate Segmentation?
                    </p>
                </div>
                <div className={"review-seg-alert-card-body"}>
                    <p className={"display-16"}>
                        It looks like you have more than 1 prostate
                        segmentation. Please check the box to confirm the
                        prostate segmentation you would like to use going
                        forward.
                    </p>
                </div>
                <div className={"review-seg-alert-card-options"}>
                    <div className={`review-seg-alert-list-item light`}>
                        <div
                            className={`review-seg-alert-list-item-color-indicator`}
                            style={{ backgroundColor: prostateUploadColor }}
                        />
                        <p
                            className={`review-seg-alert-list-item-text display-16`}
                        >
                            Prostate (User Upload)
                        </p>
                        <div className={`review-seg-alert-list-item-checkbox`}>
                            <img
                                id="rev-seg-modal-user-chk"
                                src={prostateUploadBox}
                                alt={""}
                                onClick={() =>
                                    this.onBoxClick(
                                        prostateSegOptions.UPLOADED_SEG
                                    )
                                }
                            />
                        </div>
                    </div>
                    <div className={`review-seg-alert-list-item dark`}>
                        <div
                            className={`review-seg-alert-list-item-color-indicator`}
                            style={{ backgroundColor: prostateAIColor }}
                        />
                        <p
                            className={`review-seg-alert-list-item-text display-16`}
                        >
                            Prostate (AI Generated)
                        </p>
                        <div className={`review-seg-alert-list-item-checkbox`}>
                            <img
                                id="rev-seg-modal-ai-chk"
                                src={prostateAIBox}
                                alt={""}
                                onClick={() =>
                                    this.onBoxClick(prostateSegOptions.AI_SEG)
                                }
                            />
                        </div>
                    </div>
                </div>
            </DecisionAlertCard>
        );
    }

    render() {
        let isSingleSegmentationOnly =
            (this.props.patientAnatomyModel.prostateAI == null &&
                this.props.patientAnatomyModel.prostateUploaded != null) ||
            (this.props.patientAnatomyModel.prostateUploaded == null &&
                this.props.patientAnatomyModel.prostateAI != null);

        return (
            <If condition={isSingleSegmentationOnly}>
                <Then>{this.renderSingleSegmentationDecisionAlertCard()}</Then>
                <Else>
                    {this.renderMultipleSegmentationDecisionAlertCard()}
                </Else>
            </If>
        );
    }
}

Step2ConfirmPrompt.propTypes = {
    onYesClick: PropTypes.func,
    onNoClick: PropTypes.func,
    patientAnatomyModel: PropTypes.instanceOf(PatientAnatomyModel),
    authToken: PropTypes.string,
    useruuid: PropTypes.string,
    patientuuid: PropTypes.string,
    planuuid: PropTypes.string,
    currentTreatmentPlan: PropTypes.object,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
    setCurrentTreatmentPlan: PropTypes.func,
};

Step2ConfirmPrompt.defaultProps = {
    useruuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    planuuid: EMPTY_STRING,
    onNoClick: () => {},
    onYesClick: () => {},
};

const mapStateToProps = function (state, ownProps) {
    let displaySettings = isEmpty(ownProps.displaySettings)
        ? state.ImageViewerReducer.displaySettings
        : ownProps.displaySettings;
    return {
        useruuid: state.LoginReducer.useruuid,
        authToken: state.LoginReducer.authToken,
        visituuid: state.CreatePlanReducer.visituuid,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        displaySettings: displaySettings,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Step2ConfirmPrompt);
