import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import PDFViewerContainer from "../PDFViewerContainer";
import SplitView from "../../__shared__/IconComponents/SplitView";
import { ATTACHMENTS_ERROR_MESSAGES } from "../constants";
import "./styles.css";

class PDFViewerWrapper extends Component {
    state = {
        openTab: "pathology",
        haveTabsSplit: false,
        containerWidth: 600,
    };

    pdfContainerRef = React.createRef();

    componentDidMount() {
        // Calculate width once the component mounts
        if (this.pdfContainerRef.current) {
            const containerWidth = this.pdfContainerRef.current.offsetWidth;
            if (containerWidth !== this.state.containerWidth) {
                this.setState({ containerWidth });
            }
        }
        window.addEventListener("resize", this.updateContainerWidth);
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateContainerWidth);
    }

    updateContainerWidth = () => {
        if (this.pdfContainerRef.current) {
            const containerWidth = this.pdfContainerRef.current.offsetWidth;
            if (containerWidth !== this.state.containerWidth) {
                this.setState({ containerWidth });
            }
        }
    };

    handleTabClick = (tabName) => {
        this.setState({ openTab: tabName });
    };

    toggleSplitView = () => {
        this.setState((prevState) => ({
            haveTabsSplit: !prevState.haveTabsSplit,
        }));
    };

    renderTab(tab) {
        const { openTab } = this.state;
        const tabTitle =
            tab === "pathology" ? "Pathology Report" : "Attachment";
        return (
            <div
                className={`pathTab ${
                    openTab === tab || this.state.haveTabsSplit
                        ? "pdf-tab-active"
                        : ""
                }`}
                onClick={() => this.handleTabClick(tab)}
            >
                {tabTitle}
            </div>
        );
    }
    renderHeader() {
        const { haveTabsSplit } = this.state;
        return (
            <div className="header">
                <div className="tabs">
                    {this.renderTab("pathology")}
                    {!haveTabsSplit && this.renderTab("template")}
                </div>
                <div className="split-button" onClick={this.toggleSplitView}>
                    <SplitView />
                </div>
            </div>
        );
    }

    renderTemplateHeader() {
        return (
            <div className="header">
                <div className="tabs">{this.renderTab("template")}</div>
            </div>
        );
    }

    render() {
        const {
            pathReportByteArray,
            templateByteArray,
            setPdfLoaded,
            currentTreatmentPlan,
        } = this.props;

        let hasPathologyReport =
            currentTreatmentPlan?.TreatmentPlan?.PatientData?.PathologyReportURI
                ?.length > 1;
        let hasTemplate =
            currentTreatmentPlan?.TreatmentPlan?.PatientData?.TemplateURI
                ?.length > 1;

        const { haveTabsSplit, openTab } = this.state;

        const pdfViewer =
            hasPathologyReport && pathReportByteArray?.length > 1 ? (
                <PDFViewerContainer
                    key={
                        currentTreatmentPlan?.TreatmentPlan?.PatientData
                            ?.PathologyReportURI + this.state.containerWidth
                    }
                    selectedPDF={pathReportByteArray}
                    setPdfLoaded={setPdfLoaded}
                    containerWidth={this.state.containerWidth}
                />
            ) : (
                <div className="px-5 text-center">
                    {ATTACHMENTS_ERROR_MESSAGES.NO_PATHOLOGY_REPORT_UPLOADED}
                </div>
            );

        const templateViewer =
            hasTemplate && templateByteArray?.length > 1 ? (
                <PDFViewerContainer
                    key={
                        currentTreatmentPlan?.TreatmentPlan?.PatientData
                            ?.TemplateURI + this.state.containerWidth
                    }
                    selectedPDF={templateByteArray}
                    setPdfLoaded={setPdfLoaded}
                    containerWidth={this.state.containerWidth}
                />
            ) : (
                <div className="px-4 text-center">
                    {ATTACHMENTS_ERROR_MESSAGES.NO_TEMPLATE_UPLOADED}
                </div>
            );

        return (
            <div ref={this.pdfContainerRef} className="not-split">
                {this.renderHeader()}
                <div
                    className={`${haveTabsSplit ? "path" : "expand"} ${
                        !haveTabsSplit && openTab === "template" ? "none" : ""
                    }`}
                >
                    {pdfViewer}
                </div>
                {haveTabsSplit && this.renderTemplateHeader()}
                <div
                    className={`${haveTabsSplit ? "template" : "expand"} ${
                        !haveTabsSplit && openTab === "pathology" ? "none" : ""
                    }`}
                >
                    {templateViewer}
                </div>
            </div>
        );
    }
}

PDFViewerWrapper.propTypes = {
    pathReportByteArray: PropTypes.array,
    templateByteArray: PropTypes.array,
    setPdfLoaded: PropTypes.func,
    currentTreatmentPlan: PropTypes.object,
};

const mapStateToProps = (state) => ({
    pathReportByteArray: state.CreatePlanBiopsyReducer.pathReportByteArray,
    templateByteArray: state.CreatePlanBiopsyReducer.templateByteArray,
});

export default connect(mapStateToProps)(PDFViewerWrapper);
