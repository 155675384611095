import React, { useState, forwardRef, useImperativeHandle } from "react";

import PropTypes from "prop-types";
import "./styles.css";
import "../../../styles/shared_styles.css";
import Rotate from "../../__shared__/IconComponents/Rotate";

// Toolbar in a ref to prevent re-rendering the entire PDF
const PDFViewerToolbar = forwardRef(
    ({ totalPages, zoom, zoomIn, zoomOut, rotate }, ref) => {
        const [displayPageNumber, setDisplayPageNumber] = useState(1);

        // Expose a method to update the page number
        useImperativeHandle(ref, () => ({
            updatePageNumber: (newPageNumber) => {
                setDisplayPageNumber(newPageNumber);
            },
        }));

        const spacing = "\u00A0\u00A0\u00A0";

        return (
            <div className="toolbar-wrapper">
                <div className="toolbar text-white">
                    <Rotate onClick={rotate} />
                    <div className="pdf-toolbar-zoom-cntr">
                        <button className="unstyled-btn" onClick={zoomOut}>
                            -
                        </button>
                        <div>{`|${spacing}${Math.round(
                            zoom * 100
                        )}%${spacing}|`}</div>
                        <button className="unstyled-btn" onClick={zoomIn}>
                            +
                        </button>
                    </div>
                    <div>
                        {displayPageNumber} / {totalPages}
                    </div>
                </div>
            </div>
        );
    }
);

// Define PropTypes for Toolbar
PDFViewerToolbar.propTypes = {
    displayPageNumber: PropTypes.number.isRequired, // Current page number to display
    totalPages: PropTypes.number, // Total number of pages
    zoom: PropTypes.number.isRequired, // Current zoom level
    zoomIn: PropTypes.func.isRequired, // Function to handle zoom in
    zoomOut: PropTypes.func.isRequired, // Function to handle zoom out
    rotate: PropTypes.func.isRequired, // Function to handle rotation
};

export default PDFViewerToolbar;

// Set displayName for easier debugging
PDFViewerToolbar.displayName = "Toolbar";
