import React, { Component } from "react";
import "./styles.css";
import { connect } from "react-redux";
import {
    setCurrentPatientUUID,
    setCurrentPlanUUID,
    setCurrentVisitUUID,
    setCurrentVisitDate,
} from "./actions";
import {
    createNewPatient,
    createNewPlan,
    createNewVisit,
    extractDateOnly,
    getVisit,
} from "./helpers";
import { isEmpty } from "../../helpers/helpers";
import {
    clientErrorMessages,
    EMPTY_STRING,
    pageLoadStates,
} from "../../constants";
import PropTypes from "prop-types";
import * as TreatmentPlan from "../../helpers/tplan/tplan";
import RegularUploadWorkflow from "./RegularUploadWorflow";
import FreehandUploadWorkflow from "./FreehandUploadWorflow";
import { Else, If, Then } from "react-if";
import { rootStore } from "../../redux/store";
import { setErrorState } from "../../redux/error_banner/actions";

class CreateTPlan_1Upload extends Component {
    constructor(props) {
        super(props);

        this.state = {
            pageState: pageLoadStates.LOADING,
            patientData: {},
            planMetaData: {},
        };
    }

    async componentDidMount() {
        const {
            authToken,
            useruuid,
            patientuuid,
            currentTreatmentPlan,
            /* eslint-disable react/prop-types */
            setCurrentPatientUUID,
            setCurrentPlanUUID,
            setCurrentVisitUUID,
            setCurrentVisitDate,
            /* eslint-enable react/prop-types */
        } = this.props;
        let patientData = {};
        let planMetaData = {};

        window.addEventListener(
            "dragover",
            function (e) {
                e.preventDefault();
            },
            false
        );
        window.addEventListener(
            "drop",
            function (e) {
                e.preventDefault();
            },
            false
        );

        if (!isEmpty(currentTreatmentPlan)) {
            if (
                TreatmentPlan.hasPatientData(currentTreatmentPlan.TreatmentPlan)
            ) {
                patientData = currentTreatmentPlan.TreatmentPlan.PatientData;
            }
            if (TreatmentPlan.hasMetaData(currentTreatmentPlan)) {
                planMetaData = currentTreatmentPlan.MetaData;
            }
        }

        const createNewPatientAsync = async () => {
            const patientPayload = await createNewPatient(authToken, useruuid);
            if (!isEmpty(patientPayload) && patientPayload.patientUuid) {
                const patientuuid = patientPayload.patientUuid;
                setCurrentPatientUUID(patientuuid);
                return patientuuid;
            }
            return null;
        };

        const createNewPlanAsync = async (patientuuid) => {
            const planPayload = await createNewPlan(authToken, patientuuid);
            if (!isEmpty(planPayload) && planPayload.planUuid) {
                const planuuid = planPayload.planUuid;
                setCurrentPlanUUID(planuuid);
                if (window.pendo) {
                    try {
                        window.pendo.track("Create New Plan", {
                            planId: planuuid,
                        });
                    } catch {
                        () => {};
                    }
                }
                return planuuid;
            }
            return null;
        };

        const createNewVisitAsync = async (patientuuid) => {
            const visitPayload = await createNewVisit(authToken, patientuuid);
            if (!isEmpty(visitPayload) && visitPayload.visituuid !== null) {
                setCurrentVisitUUID(visitPayload.visituuid);
            }
        };

        if (patientuuid === EMPTY_STRING) {
            try {
                const patientuuid = await createNewPatientAsync();
                if (patientuuid) {
                    const planuuid = await createNewPlanAsync(patientuuid);
                    if (planuuid) {
                        await createNewVisitAsync(patientuuid);
                        this.setState({
                            pageState: pageLoadStates.COMPLETE,
                        });
                    }
                }
            } catch (error) {
                rootStore.dispatch(
                    setErrorState(true, clientErrorMessages.TPLAN_CREATE_FAILED)
                );
            }
        } else {
            if (this.props.visitDate === EMPTY_STRING) {
                await getVisit({
                    patientuuid: this.props.patientuuid,
                    visituuid: this.props.visituuid,
                    token: this.props.authToken,
                }).then((json) => {
                    if (json) {
                        const dateOfVisit = extractDateOnly(
                            json.visit?.VisitMetadata?.DateOfVisit
                        );
                        setCurrentVisitDate(dateOfVisit);
                    }
                });
            }
            this.setState({
                pageState: pageLoadStates.COMPLETE,
                patientData,
                planMetaData,
            });
        }
    }

    //Component needs to determine whether:
    //1. New plan needs to be made or not?
    //2. A newly created plan should be either of MRI stubbed or regular type
    //3. If regular - the component presents the regular flow
    //4. else MRI stubbed version is displayed
    //5. Loading already created plans - need to determine the type then display then display correct workflow

    render() {
        return (
            <If condition={this.props.isFreehandPlan}>
                <Then>
                    <FreehandUploadWorkflow
                        patientData={this.state.patientData}
                        planMetaData={this.state.planMetaData}
                        pageState={this.state.pageState}
                    />
                </Then>
                <Else>
                    <RegularUploadWorkflow
                        patientData={this.state.patientData}
                        planMetaData={this.state.planMetaData}
                        pageState={this.state.pageState}
                    />
                </Else>
            </If>
        );
    }
}

CreateTPlan_1Upload.propTypes = {
    isFreehandPlan: PropTypes.bool,
    useruuid: PropTypes.string,
    planuuid: PropTypes.string,
    patientuuid: PropTypes.string,
    visituuid: PropTypes.string,
    visitDate: PropTypes.string,
    authToken: PropTypes.string,
    setStepActive: PropTypes.func,
    history: PropTypes.object,
    currentTreatmentPlan: PropTypes.object,
};

CreateTPlan_1Upload.defaultProps = {
    currentTreatmentPlan: {},
    authToken: EMPTY_STRING,
    useruuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    visituuid: EMPTY_STRING,
    visitDate: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        useruuid: state.LoginReducer.useruuid,
        visituuid: state.CreatePlanReducer.visituuid,
        visitDate: state.CreatePlanReducer.visitDate,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
        isFreehandPlan: state.CreatePlanReducer.isFreehandPlan,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentPatientUUID: (uuid) => dispatch(setCurrentPatientUUID(uuid)),
        setCurrentPlanUUID: (uuid) => dispatch(setCurrentPlanUUID(uuid)),
        setCurrentVisitUUID: (uuid) => dispatch(setCurrentVisitUUID(uuid)),
        setCurrentVisitDate: (date) => dispatch(setCurrentVisitDate(date)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateTPlan_1Upload);
