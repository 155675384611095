import React, { Component } from "react";
import "./styles.css";
import { TRAINING_URL, USER_MANUAL_URL } from "../../../constants";
import {
    TEMPLATE_DOCX_URL,
    TEMPLATE_PDF_URL,
} from "../../CreateTPlan_1Upload/constants";
import { ABOUT_THIS_SOFTWARE_TEXT } from "../constants";

export default class AboutThisSoftware extends Component {
    render() {
        const envClassName =
            import.meta.env.MODE === "production" ? "prod" : "dev";

        return (
            <div className={`about-this-container-${envClassName}`}>
                <div className="about-this-child-container manual-container">
                    <div className="about-this-manual-text">
                        {ABOUT_THIS_SOFTWARE_TEXT.USER_MANUAL_DESCRIPTION}
                    </div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={USER_MANUAL_URL}
                        className="about-this-button"
                    >
                        <button className="about-this-button-text">
                            {ABOUT_THIS_SOFTWARE_TEXT.USER_MANUAL_BTN}
                        </button>
                    </a>
                </div>
                <div className="about-this-child-container training-container">
                    <div id="about-this-manual-text">
                        {ABOUT_THIS_SOFTWARE_TEXT.TRAINING_DESCRIPTION}
                    </div>
                    <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={TRAINING_URL}
                        className="about-this-button"
                        id="training-link"
                    >
                        <button
                            id="start-training-btn"
                            className="about-this-button-text"
                        >
                            {ABOUT_THIS_SOFTWARE_TEXT.TRAINING_BTN}
                        </button>
                    </a>
                </div>
                <div className="about-this-child-container template-download-ctnr">
                    {ABOUT_THIS_SOFTWARE_TEXT.TEMPLATE_DESCRIPTION}
                    <div className="template-download-btn-ctnr">
                        <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={TEMPLATE_PDF_URL}
                        >
                            <button className="about-this-button-text download-template-btn">
                                {ABOUT_THIS_SOFTWARE_TEXT.TEMPLATE_BTN_PDF}
                            </button>
                        </a>
                        <a href={TEMPLATE_DOCX_URL}>
                            <button className="about-this-button-text download-template-btn">
                                {ABOUT_THIS_SOFTWARE_TEXT.TEMPLATE_BTN_DOCX}
                            </button>
                        </a>
                    </div>
                </div>
                <div className="about-this-child-container software-version-container">
                    <div>{ABOUT_THIS_SOFTWARE_TEXT.SOFTWARE_VERSION}</div>
                    <div>{import.meta.env.VITE_UI_SOFTWARE_VERSION}</div>
                    <div>{ABOUT_THIS_SOFTWARE_TEXT.UDI}</div>
                    <div>{import.meta.env.VITE_UI_SOFTWARE_UDI}</div>
                    {envClassName === "dev" ? (
                        <>
                            <div>
                                {ABOUT_THIS_SOFTWARE_TEXT.LAMBDA_ENDPOINT}
                            </div>
                            <div>{import.meta.env.VITE_API_DNS}</div>
                            <div>{ABOUT_THIS_SOFTWARE_TEXT.TASK_ENDPOINT}</div>
                            <div>{import.meta.env.VITE_TASKS_API_DNS}</div>
                        </>
                    ) : null}
                </div>
            </div>
        );
    }
}
