export const GLEASON_GRADE = {
    BENIGN: "Benign",
    GRADE1: "Grade 1 (3+3)",
    GRADE2: "Grade 2 (3+4)",
    GRADE3: "Grade 3 (4+3)",
    GRADE4: "Grade 4 (4+4)",
    GRADE5: "Grade 5 (4+5) (5+4) (5+5)",
};
export const GLEASON_GRADE_NUMS = {
    Benign: 0,
    "Grade 1 (3+3)": 1,
    "Grade 2 (3+4)": 2,
    "Grade 3 (4+3)": 3,
    "Grade 4 (4+4) (3+5) (5+3)": 4,
    "Grade 5 (4+5) (5+4) (5+5)": 5,
};

export const SystematicTemplateNames = {
    TR_12: "TR_12",
    TP_12: "TP_12",
    TP_UroNav12: "TP_UroNav12",
    TP_UroNav20: "TP_UroNav20",
    TP_PrecisionPoint: "TP_PrecisionPoint",
};

export const TemplateBiopsyLocations = {
    [SystematicTemplateNames.TR_12]: {
        RLA: { X: 0.1, Y: 0.825 },
        RLM: { X: 0.05, Y: 0.5 },
        RLB: { X: 0.075, Y: 0.125 },
        RMA: { X: 0.3, Y: 0.9 },
        RMM: { X: 0.3, Y: 0.5 },
        RMB: { X: 0.3, Y: 0.1 },
        LMA: { X: 0.575, Y: 0.9 },
        LMM: { X: 0.575, Y: 0.5 },
        LMB: { X: 0.575, Y: 0.1 },
        LLA: { X: 0.8, Y: 0.825 },
        LLM: { X: 0.825, Y: 0.5 },
        LLB: { X: 0.8, Y: 0.125 },
    },
    [SystematicTemplateNames.TP_12]: {
        RLP: { X: 0.15, Y: 0.775 },
        RLM: { X: 0.125, Y: 0.425 },
        RLA: { X: 0.15, Y: 0.125 },
        RMP: { X: 0.35, Y: 0.775 },
        RMM: { X: 0.35, Y: 0.425 },
        RMA: { X: 0.35, Y: 0.1 },
        LMP: { X: 0.625, Y: 0.775 },
        LMM: { X: 0.625, Y: 0.425 },
        LMA: { X: 0.625, Y: 0.1 },
        LLP: { X: 0.85, Y: 0.775 },
        LLM: { X: 0.875, Y: 0.425 },
        LLA: { X: 0.85, Y: 0.125 },
    },
    [SystematicTemplateNames.TP_UroNav12]: {
        RL: { X: 0.025, Y: 0.175 },
        RAA: { X: 0.25, Y: 0.02 },
        LAA: { X: 0.7, Y: 0.02 },
        LL: { X: 0.975, Y: 0.175 },
        RPA: { X: 0.2, Y: 0.3 },
        MA: { X: 0.49, Y: 0.3 },
        LPA: { X: 0.75, Y: 0.3 },
        RAB: { X: 0.25, Y: 0.65 },
        LAB: { X: 0.7, Y: 0.65 },
        RPB: { X: 0.2, Y: 0.95 },
        MB: { X: 0.475, Y: 0.95 },
        LPB: { X: 0.75, Y: 0.95 },
    },
    [SystematicTemplateNames.TP_UroNav20]: {
        RL: { X: 0.005, Y: 0.175 },
        RMAA: { X: 0.15, Y: 0.01 },
        RPAA: { X: 0.35, Y: 0.01 },
        LPAA: { X: 0.575, Y: 0.01 },
        LMAA: { X: 0.775, Y: 0.01 },
        LL: { X: 0.97, Y: 0.175 },
        RMPA: { X: 0.15, Y: 0.3 },
        RPPA: { X: 0.325, Y: 0.3 },
        MA: { X: 0.475, Y: 0.3 },
        LPPA: { X: 0.625, Y: 0.3 },
        LMPA: { X: 0.775, Y: 0.3 },
        RMAB: { X: 0.15, Y: 0.65 },
        RPAB: { X: 0.35, Y: 0.65 },
        LPAB: { X: 0.575, Y: 0.65 },
        LMAB: { X: 0.775, Y: 0.65 },
        RMPB: { X: 0.15, Y: 0.95 },
        RPPB: { X: 0.325, Y: 0.95 },
        MB: { X: 0.475, Y: 0.95 },
        LPPB: { X: 0.625, Y: 0.95 },
        LMPB: { X: 0.775, Y: 0.95 },
    },
    [SystematicTemplateNames.TP_PrecisionPoint]: {
        LB: { X: 0.7, Y: 0.8 },
        RB: { X: 0.3, Y: 0.8 },
        RPM: { X: 0.3, Y: 0.25 },
        RPL: { X: 0.1, Y: 0.25 },
        RAM: { X: 0.3, Y: 0.025 },
        RAL: { X: 0.1, Y: 0.025 },
        LPM: { X: 0.6, Y: 0.25 },
        LPL: { X: 0.9, Y: 0.25 },
        LAM: { X: 0.6, Y: 0.025 },
        LAL: { X: 0.9, Y: 0.025 },
    },
};

export const PDF_LOADING_TEXT = "Loading Path Report PDF";

export const biopsyAlertCardProps = {
    DEFAULT: {
        Title: "Confirm Biopsy Data?",
        Content:
            "Please ensure you have reviewed the biopsy data for accuracy. " +
            "Note: Cancer Estimation Map creation requires the biopsy confirmed presence of Gleason Grade Group 2+ disease " +
            "and a PI-RADS ROI.",
        noTxt: "Revise",
        yesTxt: "Confirm",
    },
    BX_HAS_ERROR: {
        Title: "Errors Found in Biopsy Data",
        Content:
            "One or more biopsy cores appear to have errors. " +
            "Please ensure you have reviewed the biopsy data for accuracy. " +
            "Biopsy cores with errors will be excluded from Cancer Estimation Map creation.",
        noTxt: "Revise",
        yesTxt: "Proceed Anyway",
    },
    CEM_NEEDS_DELETE: {
        Title: "Delete Cancer Estimation Map?",
        Content:
            "The current plan has no included biopsy cores with Gleason Grade Group > 1. " +
            "However, a Cancer Estimation Map exists for the current plan. To proceed, this Cancer Estimation Map will be deleted.",
        noTxt: "Revise",
        yesTxt: "Confirm Deletion",
    },
    CEM_NEEDS_UPDATE: {
        Title: "Update Cancer Estimation Map?",
        Content:
            "If you made any changes to the patient biopsy data, prostate segmentation, or PSA the Cancer Estimation Map needs to be regenerated.",
        noTxt: "No",
        yesTxt: "Yes",
    },
};
export const BIOPSY_ERROR_MESSAGES = {
    DEFAULT: "",
    TEMPLATE_LOCATION_MISSING:
        "Caution: Standard template location is not specified.",
    GGG_NO_TISSUE:
        "Error: Gleason Grade Group is > 0 but no tissue length is provided.",
    GGG_NO_CANCER:
        "Error: Gleason Grade Group is > 0 but no cancer length is provided.",
    CANCER_NO_GGG: "Error: Cancer length is invalid for benign biopsy.",
    CANCER_GREATER_TISSUE:
        "Error: Cancer length cannot be greater than tissue length.",
    NO_BIOPSIES_FOUND: "No biopsy cores were found for this patient.",
};

export const RESET_VIEW = "Reset View";

export const editable_column_ids = {
    FIRST_EDITABLE_COLUMN_ID: 1,
    LAST_EDITABLE_COLUMN_ID: 4,
};
export const ATTACHMENTS_ERROR_MESSAGES = {
    NO_PATHOLOGY_REPORT_UPLOADED:
        "No Pathology Report has been uploaded. Please return to Step 1 to upload a Pathology Report.",
    NO_TEMPLATE_UPLOADED:
        "No supportive documentation has been uploaded. Please return to Step 1 to upload supportive documentation.",
};
