import React, { useState, useRef, useCallback } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import PropTypes from "prop-types";
import "./styles.css";
import "../../../styles/shared_styles.css";

import { pageLoadStates } from "../../../constants";
import PDFViewerToolbar from "../PDFViewerToolbar";
pdfjs.GlobalWorkerOptions.workerSrc = "/pdf.worker.min.js";
const maxZoom = 5.0;
const zoomStep = 0.1;
const minZoom = 1.0;

const PDFViewerContainer = (props) => {
    const [totalPages, setTotalPages] = useState(null);
    const [zoom, setZoom] = useState(minZoom);
    const [rotation, setRotation] = useState(0);
    const scrollContainerRef = useRef(null);

    // Refs for panning to prevent unnecessary state updates
    const isPanning = useRef(false);
    const startX = useRef(0); // Track initial X position
    const startY = useRef(0); // Track initial Y position
    const scrollLeft = useRef(0); // Track initial horizontal scroll position
    const scrollTop = useRef(0); // Track initial vertical scroll position
    const currentPageRef = useRef(1); // Replace useState for pageNumber

    // Ref for the Toolbar
    const toolbarRef = useRef(null);

    // Ref for the scrollable container
    const pdfViewerRef = useRef(null);

    // Refs for individual pages, scoped for this instance
    const pageRefs = useRef([]);

    // Rotate Function
    const rotate = useCallback((e) => {
        e.stopPropagation();
        setRotation((prevRotation) => (prevRotation + 90) % 360);
    }, []);

    // Zoom In Function
    const zoomIn = useCallback((e) => {
        e.stopPropagation();
        setZoom((prevZoom) => Math.min(prevZoom + zoomStep, maxZoom));
    }, []);

    // Zoom Out Function
    const zoomOut = useCallback((e) => {
        e.stopPropagation();
        setZoom((prevZoom) => Math.max(prevZoom - zoomStep, minZoom));
    }, []);

    // Start Pan Function
    const startPan = useCallback((e) => {
        isPanning.current = true;
        startX.current = e.pageX - scrollContainerRef.current.offsetLeft;
        startY.current = e.pageY - scrollContainerRef.current.offsetTop;
        scrollLeft.current = scrollContainerRef.current.scrollLeft;
        scrollTop.current = scrollContainerRef.current.scrollTop;
    }, []);

    // End Pan Function
    const endPan = useCallback(() => {
        isPanning.current = false;
    }, []);

    // Pan Function
    const pan = useCallback((e) => {
        if (!isPanning.current) return;
        const x = e.pageX - scrollContainerRef.current.offsetLeft;
        const y = e.pageY - scrollContainerRef.current.offsetTop;
        const walkX = (x - startX.current) * 1; // Adjust multiplier for horizontal scroll speed
        const walkY = (y - startY.current) * 1; // Adjust multiplier for vertical scroll speed
        scrollContainerRef.current.scrollLeft = scrollLeft.current - walkX;
        scrollContainerRef.current.scrollTop = scrollTop.current - walkY;
    }, []);

    // PDF Document Load
    const onDocumentLoadSuccess = ({ numPages }) => {
        setTotalPages(numPages);

        // Initialize pageRefs with an empty array of length equal to numPages
        pageRefs.current = Array(numPages).fill(null);

        // set as loading complete
        props.setPdfLoaded(pageLoadStates.COMPLETE);
    };

    // Scroll Handler Function (for page number)
    const handleScroll = () => {
        if (!scrollContainerRef.current) return;

        const containerRect =
            scrollContainerRef.current.getBoundingClientRect();

        let mostVisiblePage = currentPageRef.current; // Start with the current page
        let maxVisibleHeight = 0;

        pageRefs.current.forEach((pageRef, index) => {
            if (!pageRef) return;

            const pageRect = pageRef.getBoundingClientRect();
            const visibleHeight =
                Math.min(containerRect.bottom, pageRect.bottom) -
                Math.max(containerRect.top, pageRect.top);

            if (visibleHeight > maxVisibleHeight) {
                maxVisibleHeight = visibleHeight;
                mostVisiblePage = index + 1; // Page numbers are 1-indexed
            }
        });

        if (mostVisiblePage !== currentPageRef.current) {
            currentPageRef.current = mostVisiblePage;
            if (toolbarRef.current) {
                toolbarRef.current.updatePageNumber(mostVisiblePage); // Dynamically update the toolbar
            }
        }
    };

    const pathReportData = new Uint8Array(props.selectedPDF);

    return (
        <div ref={pdfViewerRef} className="pdf-viewer-container pendo-ignore">
            <PDFViewerToolbar
                ref={toolbarRef} // Pass ref to Toolbar
                totalPages={totalPages}
                zoom={zoom}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                rotate={rotate}
            />
            <div
                className="pdf-scroll-container"
                ref={scrollContainerRef}
                onMouseDown={startPan}
                onMouseMove={pan}
                onMouseUp={endPan}
                onMouseLeave={endPan}
                onScroll={handleScroll}
                style={{ cursor: isPanning.current ? "grabbing" : "grab" }}
            >
                <Document
                    file={{ data: pathReportData }}
                    onLoadSuccess={onDocumentLoadSuccess}
                    loading={"Loading File..."}
                >
                    {Array.from(new Array(totalPages), (_el, index) => (
                        <Page
                            key={`page_${index + 1}_${props.selectedPDF}`}
                            pageNumber={index + 1}
                            scale={zoom}
                            width={props.containerWidth - 7} // Dynamically set width and subtract scrollbar
                            rotate={rotation}
                            className="page"
                            renderAnnotationLayer={false}
                            renderTextLayer={false}
                            inputRef={(ref) => {
                                pageRefs.current[index] = ref; // Dynamically assign the ref
                            }}
                            data-page-number={index + 1} // Page number to track
                        />
                    ))}
                </Document>
            </div>
        </div>
    );
};

PDFViewerContainer.propTypes = {
    selectedPDF: PropTypes.array.isRequired,
    setPdfLoaded: PropTypes.func,
    containerWidth: PropTypes.Number,
};

// Custom comparison function for React.memo
function areEqual(prevProps, nextProps) {
    return (
        prevProps?.selectedPDF?.length === nextProps?.selectedPDF?.length &&
            prevProps.containerWidth === nextProps.containerWidth,
        prevProps.totalPages === nextProps.totalPages
    );
}

// Wrap the component with React.memo and custom comparison function
export default React.memo(PDFViewerContainer, areEqual);
