// action types
export const SET_STEP_ACTIVE = "SET_STEP_ACTIVE";
export const SET_STEP_COMPLETE = "SET_STEP_COMPLETE";
export const RESET_STEPS = "RESET_STEPS";
export const SET_PATH_REPORT_UPLOADED = "SET_IS_PATH_REPORT_UPLOADED";
export const SET_TEMPLATE_UPLOADED = "SET_IS_TEMPLATE_UPLOADED";
export const SET_BIOPSY_DATA_UPLOADED = "SET_BIOPSY_DATA_UPLOADED";
export const SET_PATH_REPORT_THUMBNAIL_URL = "SET_PATH_REPORT_THUMBNAIL_URL";
export const SET_TEMPLATE_THUMBNAIL_URL = "SET_TEMPLATE_THUMBNAIL_URL";
export const SET_MRI_THUMBNAIL_URL = "SET_MRI_THUMBNAIL_URL";
export const SET_BIOPSY_DATA_THUMBNAIL_URL = "SET_BIOPSY_DATA_THUMBNAIL_URL";
export const SET_CURRENT_PLAN_UUID = "SET_CURRENT_PLAN_UUID";
export const SET_CURRENT_PATIENT_UUID = "SET_CURRENT_PATIENT_UUID";
export const SET_UPLOAD_STATE = "SET_UPLOAD_STATE";
export const SET_UPLOAD_PROGRESS = "SET_UPLOAD_PROGRESS";
export const SET_PARSE_PROGRESS = "SET_PARSE_PROGRESS";
export const SET_PARSE_FAILURE_MESSAGE = "SET_PARSE_FAILURE_MESSAGE";
export const SET_CURRENT_TREATMENT_PLAN = "SET_CURRENT_TREATMENT_PLAN";
export const SET_CURRENT_VISIT_UUID = "SET_CURRENT_VISIT_UUID";
export const SET_CURRENT_VISIT_DATE = "SET_CURRENT_VISIT_DATE";
export const RESET_CREATE_PLAN_SHARD = "RESET_CREATE_PLAN_SHARD";
export const SET_PATIENT_INFO_CONFIRMED = "SET_PATIENT_INFO_CONFIRMED";
export const SET_PLAN_CREATION_STEP_METADATA =
    "SET_PLAN_CREATION_STEP_METADATA";
export const SET_FREEHAND_PLAN = "SET_FREEHAND_PLAN";

// action creators
export const setStepActive = function (stepIdx, isActive) {
    return {
        type: SET_STEP_ACTIVE,
        stepIdx: stepIdx,
        isActive: isActive,
    };
};

export const setStepComplete = function (stepIdx, isComplete) {
    return {
        type: SET_STEP_COMPLETE,
        stepIdx: stepIdx,
        isComplete: isComplete,
    };
};

export const setPathReportUploaded = function (isOn) {
    return {
        type: SET_PATH_REPORT_UPLOADED,
        isOn: isOn,
    };
};

export const setTemplateUploaded = function (isOn) {
    return {
        type: SET_TEMPLATE_UPLOADED,
        isOn: isOn,
    };
};

export const setBiopsyDataUploaded = function (isOn) {
    return {
        type: SET_BIOPSY_DATA_UPLOADED,
        isOn: isOn,
    };
};

export const setPathReportThumbnailURL = function (url) {
    return {
        type: SET_PATH_REPORT_THUMBNAIL_URL,
        url: url,
    };
};

export const setTemplateThumbnailURL = function (url) {
    return {
        type: SET_TEMPLATE_THUMBNAIL_URL,
        url: url,
    };
};

export const setMriThumbnailURL = function (url) {
    return {
        type: SET_MRI_THUMBNAIL_URL,
        url: url,
    };
};

export const setBiopsyDataThumbnailURL = function (url) {
    return {
        type: SET_BIOPSY_DATA_THUMBNAIL_URL,
        url: url,
    };
};

export const setCurrentPlanUUID = function (uuid) {
    return {
        type: SET_CURRENT_PLAN_UUID,
        planuuid: uuid,
    };
};

export const setCurrentPatientUUID = function (uuid) {
    return {
        type: SET_CURRENT_PATIENT_UUID,
        patientuuid: uuid,
    };
};

export const setCurrentVisitUUID = function (uuid) {
    return {
        type: SET_CURRENT_VISIT_UUID,
        visituuid: uuid,
    };
};

export const setCurrentVisitDate = function (date) {
    return {
        type: SET_CURRENT_VISIT_DATE,
        visitDate: date,
    };
};

export const setUploadState = function (idx, upstate) {
    return {
        type: SET_UPLOAD_STATE,
        idx: idx,
        upstate: upstate,
    };
};

export const setUploadProgress = function (idx, prog) {
    return {
        type: SET_UPLOAD_PROGRESS,
        idx: idx,
        prog: prog,
    };
};

export const setParseProgress = function (idx, prog) {
    return {
        type: SET_PARSE_PROGRESS,
        idx: idx,
        prog: prog,
    };
};

export const setParseFailureMessage = function (idx, msg) {
    return {
        type: SET_PARSE_FAILURE_MESSAGE,
        idx: idx,
        msg: msg,
    };
};

export const setCurrentTreatmentPlan = function (plan) {
    return {
        type: SET_CURRENT_TREATMENT_PLAN,
        plan: plan,
    };
};

export const resetCreatePlanShard = function () {
    return {
        type: RESET_CREATE_PLAN_SHARD,
    };
};

export const setPlanCreationStepMetaData = function (stepMetaData) {
    return {
        type: SET_PLAN_CREATION_STEP_METADATA,
        stepMetaData: stepMetaData,
    };
};

export const setFreehandPlan = function (isOn) {
    return {
        type: SET_FREEHAND_PLAN,
        isOn: isOn,
    };
};
