import { specialSelection } from "../../constants";

export const initialState = {
    pathReportByteArray: [],
    templateByteArray: [],
    coreLocations: [],
    activeCore: specialSelection.NONE_SELECTED,
    isCoreFocused: false,
    isOnMeshViewerControl: false,
};
