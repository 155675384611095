import PropTypes from "prop-types";

const SplitView = ({ stroke = "#0050c4" }) => {
    return (
        <svg
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M21.1025 8.43175L21.1025 5.44282C21.1025 4.91434 20.8926 4.40751 20.5189 4.03382C20.1452 3.66013 19.6384 3.4502 19.1099 3.4502L16.121 3.4502M16.121 21.3838L19.1099 21.3838C19.6384 21.3838 20.1452 21.1739 20.5189 20.8002C20.8926 20.4265 21.1025 19.9197 21.1025 19.3912L21.1025 16.4022M3.16894 16.4022L3.16894 19.3912C3.16894 19.9196 3.37887 20.4265 3.75256 20.8002C4.12625 21.1739 4.63308 21.3838 5.16156 21.3838L8.15049 21.3838M8.15049 3.45019L5.16156 3.45019C4.63308 3.45019 4.12625 3.66013 3.75256 4.03382C3.37887 4.40751 3.16894 4.91434 3.16894 5.44282L3.16894 8.43175"
                stroke={stroke}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M24.0915 12.417L0.927256 12.417"
                stroke={stroke}
                strokeLinecap="round"
                strokeDasharray="3 3"
            />
        </svg>
    );
};

SplitView.propTypes = {
    stroke: PropTypes.string,
};

export default SplitView;
