export const PROFILE_MENU_ITEMS = {
    ACCOUNT_INFO: "Account Information",
    ABLATION_PROFILES: "Tool Profiles",
    REPORT_BUGS: "Report an Issue",
    ABOUT_THIS: "About this Software",
    SECURITY: "Security",
};

export const profileEmailMessages = {
    SUCCESS: "Email updated successfully.",
    EMAIL_ALREADY_TAKEN: "Email already taken.",
    EMPTY_STRING: "Email cannot be empty.",
};

export const profileSettingsFields = {
    PROFILE_PICTURE: "Profile Picture",
    USERNAME: "Username",
};

export const securityTitles = {
    MFA: "Multi-Factor Authentication",
    CHANGE_PASSWORD: "Change Password",
};

export const securityMessages = {
    SUCCESS: "Password updated successfully. You will need to log in again.",
    WRONG_CURRENT_PASSWORD: "Current password is incorrect.",
    PASSWORDS_DONT_MATCH: "Passwords do not match.",
    EMPTY_PASSWORD: "New password cannot be empty.",
    EMPTY_CURRENT_PASSWORD: "Current password must be provided.",
    SAME_PASSWORD: "New password must be different from current password.",
    COMMON_PASSWORD: "New password cannot be a common word.",
    PERSONAL_INFO_PASSWORD: "New password cannot contain personal information.",
};

export const securityFields = {
    CURRENT_PASSWORD: "Current password",
    NEW_PASSWORD: "New password",
    PASSWORD_CONFIRMATION: "Password confirmation",
    PASSWORD_SUBMIT: "Change Password",
};

export const ABOUT_THIS_SOFTWARE_TEXT = {
    USER_MANUAL_DESCRIPTION:
        "Download the most recent version of the User Manual for this Software",
    USER_MANUAL_BTN: "Download",
    TRAINING_DESCRIPTION: "Access Unfold AI software training resources",
    TRAINING_BTN: "Start Training",
    TEMPLATE_DESCRIPTION: "Download fusion core templates",
    TEMPLATE_BTN_PDF: "Download template (.pdf)",
    TEMPLATE_BTN_DOCX: "Download template (.docx)",
    SOFTWARE_VERSION: "Software Version:",
    UDI: "UDI:",
    LAMBDA_ENDPOINT: "Lambda API Endpoint:",
    TASK_ENDPOINT: "Task API Endpoint:",
};
