import React, { Component } from "react";
import TopBar from "../../__shared__/TopBar";
import ProfileMenu from "../../__shared__/ProfileMenu";
import StepMarkerBar from "../../__shared__/StepMarkerBar";
import AvendaLogo from "../../__shared__/AvendaLogo";
import "./styles.css";
import PreviousStepButton from "../../__shared__/PreviousStepButton";
import NextStepButton from "../../__shared__/NextStepButton";
import { connect } from "react-redux";
import {
    parsingCardLabels,
    SEG_MRI_STEP,
    UPLOAD_STEP,
    uploadStates,
} from "../constants";
import {
    setCurrentTreatmentPlan,
    setPathReportUploaded,
    setTemplateUploaded,
    setStepActive,
    setStepComplete,
    setUploadState,
    setCurrentVisitDate,
} from "../actions";
import UploadContainer from "../UploadContainer";
import {
    extractDateOnly,
    getTreatmentPlan,
    getVisit,
    updatePatientData,
    updateVisit,
} from "../helpers";
import { getLoadingScreen, isEmpty } from "../../../helpers/helpers";
import KeyPatientInfoCard from "../KeyPatientInfoCard";
import {
    EMPTY_STRING,
    pageLoadStates,
    stepPath,
    SUCCESS,
} from "../../../constants";
import RequirementsModal from "../../__shared__/RequirementsModal";
import { updateTreatmentPlan } from "../../../helpers/backend_api";
import DecisionAlertCard from "../../__shared__/DecisionAlertCard";
import PropTypes from "prop-types";
import * as TreatmentPlan from "../../../helpers/tplan/tplan";
import { withRouter } from "react-router";

class RegularUploadWorkflow extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loadState: pageLoadStates.LOADING,
            displayConfirmPatientDataAlert: false,
            biopsyParsed: false,
            mriUploaded: false,
            mriParsed: false,
            patientData: null,
            planMetaData: null,
            displayConfirmMRIDelete: false,
            lastSavedStep: UPLOAD_STEP,
        };

        this.requirements = {
            required: [
                {
                    id: "biopsy-req",
                    satisfied: this.state.biopsyParsed,
                    reqText: "Upload your Patient's Biopsy Case file (.zip)",
                    listIndex: 1,
                },
                {
                    id: "mri-req",
                    satisfied: this.state.mriParsed,
                    reqText: "Upload your Patient's MRI (.zip of DICOM)",
                    listIndex: 2,
                },
                {
                    id: "psa-req",
                    satisfied: false,
                    reqText: "Provide a valid PSA value",
                    listIndex: 3,
                },
                {
                    id: "patient-info-req",
                    satisfied: false,
                    reqText: "Confirm Key Patient Information",
                    listIndex: 4,
                },
            ],
            optionals: [
                {
                    id: "path-req",
                    satisfied: this.props.isPathReportUploaded,
                    reqText:
                        "Upload a Pathology Report for the Biopsy Case (.pdf)",
                    listIndex: 1,
                },
                {
                    id: "template-req",
                    satisfied: this.props.isTemplateUploaded,
                    reqText:
                        "Upload a Fusion ID Mapping Template for the Biopsy Case (.pdf)",
                    listIndex: 2,
                },
            ],
        };

        this.patientKeyInfoCardRef = React.createRef();
        this.nextStep = this.nextStep.bind(this);
        this.prevStep = this.prevStep.bind(this);
        this.requirementsCheck = this.requirementsCheck.bind(this);
        this.getRequirements = this.getRequirements.bind(this);
        this.mriUploadCheck = this.mriUploadCheck.bind(this);
        this.verifyPatientDataBtnCallback =
            this.verifyPatientDataBtnCallback.bind(this);
        this.getConfirmPatientDataAlert =
            this.getConfirmPatientDataAlert.bind(this);
        this.setBiopsyParsed = this.setBiopsyParsed.bind(this);
        this.setMriUploaded = this.setMriUploaded.bind(this);
        this.updatePatientDetails = this.updatePatientDetails.bind(this);
        this.checkPSAValid = this.checkPSAValid.bind(this);
        this.checkPatientDataValid = this.checkPatientDataValid.bind(this);
        this.setMriParsed = this.setMriParsed.bind(this);
        this.resetPlanProgress = this.resetPlanProgress.bind(this);
        this.loadUploadedPlanInfo = this.loadUploadedPlanInfo.bind(this);
        this.saveTreatmentPlan = this.saveTreatmentPlan.bind(this);
        this.autoSaveTreatmentPlan = this.autoSaveTreatmentPlan.bind(this);
    }

    mriUploadCheck(tplan) {
        if (TreatmentPlan.hasMRI(tplan)) {
            this.setState({
                mriUploaded: true,
                mriParsed: true,
            });
        } else {
            this.setState({
                mriUploaded: false,
                mriParsed: false,
            });
        }
    }

    loadUploadedPlanInfo(biopsyParsedState) {
        let patientData = {},
            planMetaData = {};

        if (biopsyParsedState) {
            this.mriUploadCheck(this.props.currentTreatmentPlan.TreatmentPlan);

            if (!isEmpty(this.props.currentTreatmentPlan)) {
                if (
                    TreatmentPlan.hasPatientData(
                        this.props.currentTreatmentPlan.TreatmentPlan
                    )
                ) {
                    patientData =
                        this.props.currentTreatmentPlan.TreatmentPlan
                            .PatientData;
                }

                if (
                    TreatmentPlan.hasMetaData(this.props.currentTreatmentPlan)
                ) {
                    planMetaData = this.props.currentTreatmentPlan.MetaData;
                }
            }
        }

        return { patientData: patientData, planMetaData: planMetaData };
    }

    setBiopsyParsed(biopsyParsedState) {
        let loadedInfo = this.loadUploadedPlanInfo(biopsyParsedState);

        this.setState({
            biopsyParsed: biopsyParsedState,
            patientData: loadedInfo.patientData,
            planMetaData: loadedInfo.planMetaData,
        });
    }

    setMriUploaded(mriUploadedState) {
        this.setState({ mriUploaded: mriUploadedState });
    }

    setMriParsed(mriParsedState) {
        this.setState({ mriParsed: mriParsedState });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({
            loadState: nextProps.pageState,
        });
    }
    componentDidMount() {
        if (TreatmentPlan.hasPathologyReport(this.props.currentTreatmentPlan)) {
            this.props.setUploadState(1, uploadStates.COMPLETE);
            this.props.setPathReportUploaded(true);
        }
        if (TreatmentPlan.hasTemplate(this.props.currentTreatmentPlan)) {
            this.props.setUploadState(3, uploadStates.COMPLETE);
            this.props.setTemplateUploaded(true);
        }
        this.mriUploadCheck(this.props.currentTreatmentPlan.TreatmentPlan);
        this.setState({
            loadState: this.props.pageState,
            patientData: this.props.patientData,
            planMetaData: this.props.planMetaData,
            lastSavedStep: TreatmentPlan.getPlanCreationStep(
                this.props.currentTreatmentPlan
            ),
        });
    }

    componentDidUpdate(prevProps, prevState) {
        const isUploadedContentChanged =
            (prevProps.isPathReportUploaded &&
                !this.props.isPathReportUploaded) ||
            (prevProps.isTemplateUploaded && !this.props.isTemplateUploaded) ||
            prevState.mriUploaded !== this.state.mriUploaded;

        const isBiopsyParsedChanged =
            this.state.biopsyParsed !== prevState.biopsyParsed;

        const isVisitDateMissing =
            !this.props.visitDate || this.props.visitDate === EMPTY_STRING;

        if (isUploadedContentChanged && this.state.biopsyParsed) {
            this.autoSaveTreatmentPlan();
        }

        if (
            isBiopsyParsedChanged &&
            this.state.biopsyParsed &&
            isVisitDateMissing
        ) {
            getVisit({
                patientuuid: this.props.patientuuid,
                visituuid: this.props.visituuid,
                token: this.props.authToken,
            }).then((json) => {
                if (json) {
                    const dateOfVisit = extractDateOnly(
                        json.visit?.VisitMetadata?.DateOfVisit
                    );
                    this.props.setCurrentVisitDate(dateOfVisit);
                }
            });
        }
    }

    prevStep() {
        this.autoSaveTreatmentPlan();
    }

    async nextStep() {
        if (!this.requirementsCheck()) {
            this.requirementsModalRef.openModal();
            return;
        }

        let scope = this;
        let plan = await this.saveTreatmentPlan(SEG_MRI_STEP);

        if (plan) {
            scope.props.history.push(stepPath.STEP2);
            scope.props.setStepComplete(UPLOAD_STEP, true);
            scope.props.setStepActive(UPLOAD_STEP, false);
            scope.props.setStepActive(SEG_MRI_STEP, true);
            scope.props.setCurrentTreatmentPlan(plan);
        }
    }

    checkPSAValid() {
        if (this.patientKeyInfoCardRef.current) {
            return this.state.patientData
                ? this.patientKeyInfoCardRef.current.isPSAValid(
                      this.state.patientData
                  )
                : false;
        }

        return false;
    }

    checkPatientDataValid() {
        if (!this.patientKeyInfoCardRef.current) {
            return false;
        }

        if (!this.state.patientData) {
            return false;
        }

        return this.patientKeyInfoCardRef.current.isPatientDataValid(
            this.state.patientData
        );
    }

    async resetPlanProgress(label) {
        let plan = this.props.currentTreatmentPlan;

        plan.SavedData.PlanCreationStep = UPLOAD_STEP;

        this.props.setStepComplete(UPLOAD_STEP, false);
        this.props.setStepActive(UPLOAD_STEP, true);

        if (label === parsingCardLabels.MRI) {
            plan.SavedData.IsMriUploaded = false;
            if (plan.TreatmentPlan.AdditionalData?.MRI) {
                plan.TreatmentPlan.AdditionalData.MRI[0] = {};
            }
        } else if (label === parsingCardLabels.REPORT) {
            plan.SavedData.IsPathReportUploaded = false;
            plan.TreatmentPlan.PatientData.PathologyReportURI = EMPTY_STRING;
        } else if (label === parsingCardLabels.TEMPLATE) {
            plan.SavedData.IsTemplateUploaded = false;
            plan.TreatmentPlan.PatientData.TemplateURI = EMPTY_STRING;
        }

        this.setState({ patientData: plan.TreatmentPlan.PatientData });
        this.props.setCurrentTreatmentPlan(plan);
    }

    requirementsCheck() {
        return (
            this.state.mriParsed &&
            this.state.biopsyParsed &&
            this.checkPSAValid() &&
            this.checkPatientDataValid()
        );
    }

    getRequirements() {
        this.requirements.required[0].satisfied = this.state.biopsyParsed;
        this.requirements.required[1].satisfied = this.state.mriParsed;
        this.requirements.required[2].satisfied = this.checkPSAValid();
        this.requirements.required[3].satisfied = this.checkPatientDataValid();
        this.requirements.optionals[0].satisfied =
            this.props.isPathReportUploaded;
        this.requirements.optionals[1].satisfied =
            this.props.isTemplateUploaded;
        return this.requirements;
    }

    verifyPatientDataBtnCallback() {
        if (this.requirementsCheck()) {
            this.setState({ displayConfirmPatientDataAlert: true });
            return true;
        } else if (this.requirementsModalRef.state.modalOpen) {
            this.requirementsModalRef.closeModal();
            return false;
        } else {
            this.requirementsModalRef.openModal();
            return false;
        }
    }

    getConfirmPatientDataAlert(isOn) {
        if (!isOn) {
            return null;
        }

        return (
            <div>
                <DecisionAlertCard
                    id={"review-patient-data-alert"}
                    noBtnCallback={() => {
                        this.setState({
                            displayConfirmPatientDataAlert: false,
                        });
                    }}
                    noBtnTxt={"Revise"}
                    yesBtnTxt={"Confirm"}
                    yesBtnCallback={this.nextStep}
                >
                    <div className={"review-patient-data-alert-card-header"}>
                        <p className={"display-26 black-text"}>
                            Confirm Patient Data?
                        </p>
                    </div>
                    <div className={"review-patient-data-alert-card-body"}>
                        <div className={"review-patient-data-alert-row"}>
                            <p className={"display-16 black-text"}>
                                Please ensure you have reviewed the patient data
                                for accuracy.
                            </p>
                        </div>
                    </div>
                </DecisionAlertCard>
                <div id={"review-patient-data-alert-overlay"} />
            </div>
        );
    }

    async autoSaveTreatmentPlan() {
        let step = TreatmentPlan.getPlanCreationStep(
            this.props.currentTreatmentPlan
        );
        if (step !== UPLOAD_STEP) {
            this.setState({ lastSavedStep: step });
        }

        let isStepComplete = this.requirementsCheck();

        if (
            isStepComplete &&
            step === UPLOAD_STEP &&
            this.state.lastSavedStep !== UPLOAD_STEP
        ) {
            step = this.state.lastSavedStep;
        }

        if (!isStepComplete && step !== UPLOAD_STEP) {
            step = UPLOAD_STEP;
        }

        let plan = await this.saveTreatmentPlan(step);
        if (plan) {
            this.props.setStepActive(UPLOAD_STEP, true);
            this.props.setCurrentTreatmentPlan(plan);
        }
    }

    async saveTreatmentPlan(planCreationStep) {
        let plan;
        await getTreatmentPlan(
            this.props.authToken,
            this.props.patientuuid,
            this.props.planuuid
        )
            .then((payload) => payload.json())
            .then((json) => {
                if (json.payload.status === SUCCESS) {
                    plan = json.payload.plan;
                } else {
                    plan = this.props.currentTreatmentPlan;
                }
            })
            .catch(() => {
                plan = this.props.currentTreatmentPlan;
            });

        if (plan) {
            plan.TreatmentPlan.PatientData = this.state.patientData;
            plan.MetaData = this.state.planMetaData;

            plan.SavedData.IsPathReportUploaded =
                this.props.isPathReportUploaded;

            plan.SavedData.IsTemplateUploaded = this.props.isTemplateUploaded;

            plan.SavedData.IsMriUploaded = this.state.mriUploaded;

            plan.TreatmentPlan.PatientData.PSA = !isNaN(
                plan.TreatmentPlan.PatientData.PSA
            )
                ? parseFloat(plan.TreatmentPlan.PatientData.PSA)
                : 0;

            plan.SavedData.PlanCreationStep = planCreationStep;

            if (plan.TreatmentPlan.AdditionalData?.MRI?.[0]) {
                plan.TreatmentPlan.AdditionalData.MRI[0] =
                    this.props.currentTreatmentPlan.TreatmentPlan.AdditionalData.MRI[0];
            }

            await updateTreatmentPlan({
                useruuid: this.props.useruuid,
                patientuuid: this.props.patientuuid,
                planuuid: this.props.planuuid,
                token: this.props.authToken,
                plan: plan,
            });

            await updatePatientData({
                patientuuid: this.props.patientuuid,
                token: this.props.authToken,
                patientData: plan.TreatmentPlan.PatientData,
            });

            await updateVisit({
                visituuid: this.props.visituuid,
                patientuuid: this.props.patientuuid,
                visit: {
                    FirstName: plan.TreatmentPlan.PatientData.FirstName,
                    MiddleName: plan.TreatmentPlan.PatientData.MiddleName,
                    LastName: plan.TreatmentPlan.PatientData.LastName,
                    PatientMRN: plan.TreatmentPlan.PatientData.PatientMRN,
                    DOB: plan.TreatmentPlan.PatientData.DOB,
                    Race: plan.TreatmentPlan.PatientData.Race,
                    Ethnicity: plan.TreatmentPlan.PatientData.Ethnicity,
                },
                token: this.props.authToken,
            });
        }
        return plan;
    }

    updatePatientDetails(patData, metaData) {
        this.setState({
            patientData: patData,
            planMetaData: metaData,
        });
    }

    render() {
        let requirements = this.getRequirements();

        return (
            <div>
                <div className={"create-plan-upload-screen"}>
                    <TopBar>
                        <AvendaLogo
                            id={"create-plan-avenda-logo"}
                            to={stepPath.HOME}
                        />
                        <div className={"create-plan-step-bar-container"}>
                            <div className={"step-bar-prev-btn-group"}>
                                <PreviousStepButton
                                    to={stepPath.HOME}
                                    callback={this.prevStep}
                                    isReady={
                                        this.state.loadState !==
                                        pageLoadStates.LOADING
                                    }
                                />
                                <StepMarkerBar />
                            </div>
                            <NextStepButton
                                reqsComplete={this.requirementsCheck()}
                                reqsCompleteTxt={"Verify Patient Data"}
                                noLink={true}
                                text={""}
                                to={stepPath.STEP2}
                                callback={this.verifyPatientDataBtnCallback}
                            />
                        </div>
                        <ProfileMenu />
                    </TopBar>
                    <RequirementsModal
                        onRef={(ref) => (this.requirementsModalRef = ref)}
                        requirements={requirements}
                    />
                    {getLoadingScreen(this.state.loadState)}
                    <KeyPatientInfoCard
                        ref={this.patientKeyInfoCardRef}
                        updatePatientDetails={this.updatePatientDetails}
                        isBiopsyDataParsed={this.state.biopsyParsed}
                        patientData={this.state.patientData}
                        metaData={this.state.planMetaData}
                        autoSaveCallback={this.autoSaveTreatmentPlan}
                        visitDate={this.props.visitDate}
                    />
                    <UploadContainer
                        setMriParsed={this.setMriParsed}
                        setMriUploaded={this.setMriUploaded}
                        isBiopsyDataParsed={this.state.biopsyParsed}
                        mriUploaded={this.state.mriUploaded}
                        mriParsed={this.state.mriParsed}
                        setBiopsyDataParsed={this.setBiopsyParsed}
                        resetPlanProgress={this.resetPlanProgress}
                    />
                </div>
                {this.getConfirmPatientDataAlert(
                    this.state.displayConfirmPatientDataAlert
                )}
            </div>
        );
    }
}

RegularUploadWorkflow.propTypes = {
    isPathReportUploaded: PropTypes.bool,
    isTemplateUploaded: PropTypes.bool,
    useruuid: PropTypes.string,
    planuuid: PropTypes.string,
    patientuuid: PropTypes.string,
    visituuid: PropTypes.string,
    visitDate: PropTypes.string,
    authToken: PropTypes.string,
    setStepActive: PropTypes.func,
    setStepComplete: PropTypes.func,
    history: PropTypes.object,
    pageState: PropTypes.string,
    patientData: PropTypes.object,
    planMetaData: PropTypes.object,
    currentTreatmentPlan: PropTypes.object,
    setCurrentTreatmentPlan: PropTypes.func,
    setCurrentPatientUUID: PropTypes.func,
    setCurrentPlanUUID: PropTypes.func,
    setCurrentVisitUUID: PropTypes.func,
    setCurrentVisitDate: PropTypes.func,
    setUploadState: PropTypes.func,
    setPathReportUploaded: PropTypes.func,
    setTemplateUploaded: PropTypes.func,
};

RegularUploadWorkflow.defaultProps = {
    currentTreatmentPlan: {},
    authToken: EMPTY_STRING,
    useruuid: EMPTY_STRING,
    patientuuid: EMPTY_STRING,
    isPathReportUploaded: false,
    isTemplateUploaded: false,
    visituuid: EMPTY_STRING,
    visitDate: EMPTY_STRING,
};

const mapStateToProps = function (state) {
    return {
        authToken: state.LoginReducer.authToken,
        useruuid: state.LoginReducer.useruuid,
        visituuid: state.CreatePlanReducer.visituuid,
        visitDate: state.CreatePlanReducer.visitDate,
        patientuuid: state.CreatePlanReducer.patientuuid,
        planuuid: state.CreatePlanReducer.planuuid,
        isPathReportUploaded: state.CreatePlanReducer.isPathReportUploaded,
        isTemplateUploaded: state.CreatePlanReducer.isTemplateUploaded,
        currentTreatmentPlan: state.CreatePlanReducer.currentTreatmentPlan,
    };
};

const mapDispatchToProps = function (dispatch) {
    return {
        setCurrentTreatmentPlan: (plan) =>
            dispatch(setCurrentTreatmentPlan(plan)),
        setStepActive: (stepIdx, isActive) =>
            dispatch(setStepActive(stepIdx, isActive)),
        setStepComplete: (stepIdx, isComplete) =>
            dispatch(setStepComplete(stepIdx, isComplete)),
        setPathReportUploaded: (isOn) => dispatch(setPathReportUploaded(isOn)),
        setTemplateUploaded: (isOn) => dispatch(setTemplateUploaded(isOn)),
        setUploadState: (idx, upstate) =>
            dispatch(setUploadState(idx, upstate)),
        setCurrentVisitDate: (date) => dispatch(setCurrentVisitDate(date)),
    };
};

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(RegularUploadWorkflow)
);
